<template>
  <div>
    <v-row class="pa-4">
      <v-col cols="12" md="8" class="pt-0">
        <vue-cropper-js
          ref="cropper"
          :aspect-ratio="1"
          :src="imgSrc"
          :data="data"
          preview=".preview"
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-row>
          <v-col cols="12" md="6" class="pa-1">
            <v-btn
              class="rounded-lg"
              elevation="2"
              text
              color="primary"
              @click.prevent="zoom(0.2)"
              >Zoom In</v-btn
            >
          </v-col>
          <v-col cols="12" md="6" class="pa-1">
            <v-btn
              class="rounded-lg"
              elevation="2"
              text
              color="primary"
              @click.prevent="zoom(-0.2)"
              >Zoom Out</v-btn
            ></v-col
          >
          <v-col cols="12" md="6" class="pa-1">
            <v-btn
              class="rounded-lg"
              elevation="2"
              text
              color="primary"
              @click.prevent="move(-10, 0)"
              >Move Left</v-btn
            >
          </v-col>
          <v-col cols="12" md="6" class="pa-1">
            <v-btn
              class="rounded-lg"
              elevation="2"
              text
              color="primary"
              @click.prevent="move(10, 0)"
              >Move Right</v-btn
            ></v-col
          >
          <v-col cols="12" md="6" class="pa-1">
            <v-btn
              class="rounded-lg"
              elevation="2"
              text
              color="primary"
              @click.prevent="move(0, -10)"
              >Move Up</v-btn
            >
          </v-col>
          <v-col cols="12" md="6" class="pa-1">
            <v-btn
              class="rounded-lg"
              elevation="2"
              text
              color="primary"
              @click.prevent="move(0, 10)"
              >Move Down</v-btn
            ></v-col
          >
          <v-col cols="12" md="6" class="pa-1">
            <v-btn
              class="rounded-lg"
              elevation="2"
              text
              color="primary"
              @click.prevent="rotate(90)"
              >Rotate +90deg</v-btn
            >
          </v-col>
          <v-col cols="12" md="6" class="pa-1">
            <v-btn
              class="rounded-lg"
              elevation="2"
              text
              color="primary"
              @click.prevent="rotate(-90)"
              >Rotate -90deg</v-btn
            ></v-col
          >
          <v-col cols="12" class="pa-1">
            <v-btn
              class="rounded-lg"
              elevation="2"
              text
              color="primary"
              @click.prevent="reset"
              >Reset</v-btn
            >
          </v-col>
        </v-row>
        <!-- <a ref="flipX" href="#" role="button" @click.prevent="flipX">
                Flip X
              </a>
              <a ref="flipY" href="#" role="button" @click.prevent="flipY">
                Flip Y
              </a> -->
        <!-- <v-btn size="small" color="secondary" variant="outlined" @click.prevent="cropImage"
            >Crop</v-btn
          > -->
        <!-- <v-btn size="small" color="secondary" variant="outlined" @click.prevent="getData"
            >Get Data</v-btn
          >
          <v-btn size="small" color="secondary" variant="outlined" @click.prevent="setData"
            >Set Data</v-btn
          >
          <v-btn size="small" color="secondary" variant="outlined" @click.prevent="getCropBoxData"
            >Get CropBox Data</v-btn
          >
          <v-btn size="small" color="secondary" variant="outlined" @click.prevent="setCropBoxData"
            >Set CropBox Data</v-btn
          > -->
        <!-- <v-btn size="small" color="secondary" variant="outlined" @click.prevent="showFileChooser"
            >Upload Image</v-btn
          > -->
      </v-col>
      <!-- <v-col cols="12" md="6">
        <section class="preview-area">
          <p>Preview</p>
          <div class="preview" /></section
      ></v-col> -->
    </v-row>
    <!-- <section class="cropper-area">
        <v-textarea v-model="data" />
      </section> -->
    <!-- <p>Cropped Image</p>
      <div class="cropped-image">
        <img v-if="cropImg" :src="cropImg" alt="Cropped Image" />
        <div v-else class="crop-placeholder" />
      </div> -->
    <v-row justify="center">
      <v-col
        cols="12"
        class="pa-10 d-flex justify-center"
        @click="saveProfilePic"
        ><v-btn class="primary rounded-lg">Save</v-btn></v-col
      >
    </v-row>
  </div>
</template>

<script>
import VueCropperJs from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

export default {
  name: "ProfilePictureUpload",
  components: {
    VueCropperJs,
  },
  props: {
    profileImage: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      imgSrc: null,
      cropImg: "",
      data: null,
    };
  },
  async mounted() {
    if (this.profileImage) {
      const pUrl = this.profileImage;
      this.$refs.cropper.replace(pUrl);
    }
  },
  methods: {
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
    },
    flipX() {
      const dom = this.$refs.flipX;
      let scale = dom.getAttribute("data-scale");
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleX(scale);
      dom.setAttribute("data-scale", scale);
    },
    flipY() {
      const dom = this.$refs.flipY;
      let scale = dom.getAttribute("data-scale");
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleY(scale);
      dom.setAttribute("data-scale", scale);
    },
    getCropBoxData() {
      this.data = JSON.stringify(this.$refs.cropper.getCropBoxData(), null, 4);
    },
    getData() {
      this.data = JSON.stringify(this.$refs.cropper.getData(), null, 4);
    },
    move(offsetX, offsetY) {
      this.$refs.cropper.move(offsetX, offsetY);
    },
    reset() {
      this.$refs.cropper.reset();
    },
    rotate(deg) {
      this.$refs.cropper.rotate(deg);
    },
    setCropBoxData() {
      if (!this.data) return;

      this.$refs.cropper.setCropBoxData(JSON.parse(this.data));
    },
    setData() {
      if (!this.data) return;

      this.$refs.cropper.setData(JSON.parse(this.data));
    },
    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent);
    },
    saveProfilePic() {
      let imgAsbase64 = this.$refs.cropper.getCroppedCanvas().toDataURL();
      let fileExtension = imgAsbase64.substring(
        "data:image/".length,
        imgAsbase64.indexOf(";base64")
      );
      this.$emit("image-uploaded", [imgAsbase64, fileExtension]);
    },
  },
};
</script>

<style>
input[type="file"] {
  display: none;
}

.cropper-container {
  min-height: 200px !important;
}

.preview {
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.crop-placeholder {
  width: 100%;
  height: 200px;
  background: #ccc;
}

.cropped-image img {
  max-width: 100%;
}
</style>
