<template>
  <div class="full-height">
    <div v-if="windowWidth > 960" class="mt-4 d-flex justify-center">
      <div
        v-for="(detail, index) in progressDetails"
        :key="detail.type"
        class="text-center"
      >
        <div class="d-flex align-center">
          <div>
            <v-progress-circular
              :rotate="-90"
              :size="45"
              :width="5"
              :value="
                index === selectedSection || selectedSection > index ? 100 : 0
              "
              :color="selectedSection > index ? 'green' : 'primary'"
            >
              <v-icon
                size="20"
                class="font-weight-bold"
                :color="selectedSection > index ? 'green' : 'primary'"
              >
                {{ selectedSection > index ? "check" : detail.icon }}</v-icon
              >
            </v-progress-circular>
            <div
              :class="{ 'font-weight-bold': index === selectedSection }"
              class="body-2 mt-1"
              :color="
                selectedSection > index ? 'color: green' : 'color: primary'
              "
            >
              <span
                :color="
                  selectedSection > index ? 'color: green' : 'color: primary'
                "
              >
                {{ detail.heading }}
              </span>
            </div>
          </div>
          <div
            v-if="detail.type !== 'submit'"
            style="width: 70px; border-top: 2px dotted #b1afaf"
          ></div>
        </div>
      </div>
    </div>
    <PersonalDetails
      v-if="selectedSection === 0"
      @personal-details="personalDetails = $event"
      @move-to-next-section="onClickNextSession(1)"
    ></PersonalDetails>
    <SkillProfiles
      v-else-if="selectedSection === 1"
      @back-to-prev="backToPreviousStep(0)"
      @experience-saved="onClickNextSession(2)"
    ></SkillProfiles>
    <Certification
      v-else-if="selectedSection === 2"
      @back-to-prev="backToPreviousStep(1)"
      @move-to-next-session="onClickNextSession(3)"
    ></Certification>
    <Accreditations
      v-else-if="selectedSection === 3"
      :personalDetails="personalDetails"
      @back-to-prev="backToPreviousStep(2)"
      @accreditations-saved="onClickNextSession(4)"
    ></Accreditations>
    <Documents
      v-else-if="selectedSection === 4"
      @back-to-prev="backToPreviousStep(3)"
      @documents-saved="onClickNextSession(5)"
    ></Documents>
    <BankInformation
      v-else-if="selectedSection === 5"
      @back-to-prev="backToPreviousStep(4)"
      @bank-saved="onClickNextSession(6)"
    ></BankInformation>
    <FormSubmit
      v-else
      @back-to-prev="backToPreviousStep(5)"
      @form-submitted="$emit('form-submitted')"
    ></FormSubmit>
  </div>
</template>

<script>
import PersonalDetails from "./personal-details/PersonalDetails.vue";
import SkillProfiles from "./skill-profile/SkillProfiles.vue";
import Certification from "./certification/Certification.vue";
import Accreditations from "./accreditations/Accreditations.vue";
import Documents from "./documents/Documents.vue";
import BankInformation from "./bank-details/BankInformation.vue";
import FormSubmit from "./FormSubmit.vue";

export default {
  name: "Onboarding",

  components: {
    PersonalDetails,
    SkillProfiles,
    Certification,
    Accreditations,
    Documents,
    BankInformation,
    FormSubmit,
  },

  data: () => ({
    selectedSection: 0,
    personalDetails: {},
    progressDetails: [
      {
        type: "personal",
        value: 0,
        icon: "person",
        heading: "Personal Information",
      },
      {
        type: "skills",
        value: 1,
        icon: "business_center",
        heading: "Work Experience",
      },
      {
        type: "certification",
        value: 2,
        icon: "description",
        heading: "Certification",
      },
      {
        type: "accreditation",
        value: 3,
        icon: "article",
        heading: "Accreditation",
      },
      {
        type: "documents",
        value: 4,
        icon: "upload_file",
        heading: "Documents",
      },
      {
        type: "bank",
        value: 5,
        icon: "account_balance",
        heading: "Bank Information",
      },
      {
        type: "submit",
        value: 6,
        icon: "checklist_rtl",
        heading: "Submit",
      },
    ],
  }),

  computed: {
    // current window size
    windowWidth() {
      return this.$store.state.windowWidth;
    },
  },

  methods: {
    onClickNextSession(nextStep) {
      this.selectedSection = nextStep;
    },
    backToPreviousStep(toStep) {
      this.selectedSection = toStep;
    },
  },
};
</script>
