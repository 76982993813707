<template>
  <div>
    <div class="d-flex justify-end mb-3">
      <v-btn color="primary" text @click="addMoreTraining()">
        <v-icon>add</v-icon>
        Add Training
      </v-btn>
    </div>
    <v-form
      v-for="(training, index) in addTraining"
      :key="index"
      :ref="'trainingForm' + index"
    >
      <div
        v-if="addTraining.length > 1 || training.trainingId"
        class="d-flex justify-end mb-3"
      >
        <v-btn
          color="primary lighten-1"
          text
          @click="removeTraining(index, training)"
        >
          <v-icon>delete</v-icon>
          Remove
        </v-btn>
      </div>
      <ValidationObserver :ref="'trainingForm' + index">
        <v-row>
          <v-col cols="12">
            <v-card flat class="pa-6">
              <v-row>
                <v-col cols="12" lg="6" xl="6" md="6" sm="12" xs="12">
                  <ValidationProvider
                    ref="trainingNameProvider"
                    v-slot="{ errors }"
                    name="Training Name"
                    rules="required|multilingualNameNumericValidation"
                  >
                    <v-text-field
                      :id="'TrainingName'"
                      v-model="training.trainingName"
                      outlined
                      :counter="30"
                      :maxlength="30"
                      label="Training Name*"
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" lg="6" xl="6" md="6" sm="12" xs="12">
                  <v-dialog v-model="startDateModal" width="290">
                    <template #activator="{ on }">
                      <ValidationProvider
                        ref="startDateProvider"
                        v-slot="{ errors }"
                        name="Start Date"
                        rules="required"
                      >
                        <v-text-field
                          :id="'TrainingStartDate'"
                          :value="formatDate(training.startDate)"
                          outlined
                          readonly
                          label="Start Date*"
                          v-on="on"
                          :error-messages="errors"
                        ></v-text-field>
                      </ValidationProvider>
                    </template>
                    <v-date-picker
                      v-model="training.startDate"
                      :min="minJoinDate"
                      :max="maxYear"
                      actions
                      @change="emptyEnd(index)"
                    >
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" lg="6" xl="6" md="6" sm="12" xs="12">
                  <v-dialog v-model="endDateModal" width="290">
                    <template #activator="{ on }">
                      <ValidationProvider
                        ref="endDateProvider"
                        v-slot="{ errors }"
                        name="End Date"
                        rules="required"
                      >
                        <v-text-field
                          :id="'TrainingEndDate'"
                          :value="formatDate(training.endDate)"
                          readonly
                          outlined
                          :disabled="!training.startDate"
                          label="End Date*"
                          v-on="on"
                          :error-messages="errors"
                        ></v-text-field>
                      </ValidationProvider>
                    </template>
                    <v-date-picker
                      v-model="training.endDate"
                      :min="training.startDate"
                      @change="endDateModal = false"
                    ></v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" lg="6" xl="6" md="6" sm="12" xs="12">
                  <ValidationProvider
                    ref="trainingCenterProvider"
                    v-slot="{ errors }"
                    name="Training Center"
                    rules="required|multilingualNameNumericValidation"
                  >
                    <v-text-field
                      :id="'TrainingCenter'"
                      v-model="training.trainingCenter"
                      :maxlength="30"
                      outlined
                      :counter="30"
                      label="Training Center*"
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" lg="6" xl="6" md="6" sm="12" xs="12">
                  <ValidationProvider
                    ref="trainerProvider"
                    v-slot="{ errors }"
                    name="Trainer"
                    rules="required|multilingualNameNumericValidation"
                  >
                    <v-text-field
                      :id="'TrainerName'"
                      v-model="training.trainer"
                      outlined
                      :counter="30"
                      :maxlength="30"
                      label="Trainer*"
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" lg="6" xl="6" md="6" sm="12" xs="12">
                  <DragAndDropFiles
                    :id="'train' + index"
                    file-category="document"
                    :key-index="'train-file-upload-' + index"
                    :old-file-name="training.fileName"
                    @file-event-success="updateFileName($event, index)"
                    @file-event-triggered="isFileLoading = $event"
                    :message="true"
                  ></DragAndDropFiles>
                </v-col>
                <v-col
                  v-if="training.fileNameWithoutFormat"
                  cols="12"
                  lg="6"
                  xl="6"
                  md="6"
                  sm="12"
                  xs="12"
                >
                  <ValidationProvider
                    ref="cSubTypeProvider"
                    v-slot="{ errors }"
                    name="Document Sub Type"
                    :rules="training.fileNameWithoutFormat ? 'required' : ''"
                  >
                    <v-autocomplete
                      :id="'DocumentSubType'"
                      v-model="training.trainingSubType"
                      outlined
                      :items="subTypeList"
                      item-text="Document_Sub_Type"
                      item-value="Document_Sub_Type_Id"
                      label="Document Sub Type*"
                      :error-messages="errors"
                    ></v-autocomplete>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </ValidationObserver>
      <v-divider></v-divider>
    </v-form>
    <div class="mt-3 d-flex justify-end">
      <v-btn
        color="primary"
        style="border-radius: 10px"
        large
        :loading="saveLoading"
        :disabled="isFileLoading"
        @click="onSaveTraining()"
        >Save & Continue</v-btn
      >
      <v-btn
        v-if="showSkip"
        class="ml-2"
        elevation="2"
        text
        color="primary"
        style="border-radius: 10px"
        large
        @click="onSkipTraining()"
        >Skip</v-btn
      >
    </div>
    <v-overlay
      absolute
      :value="trainingLoading || saveLoading"
      color="#fff"
      z-index="1"
    >
      <v-progress-circular
        color="primary"
        indeterminate
        size="50"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import {
  GET_TRAINING,
  ADD_TRAINING,
  UPDATE_TRAINING,
  DELETE_TRAINING,
  LIST_SUB_DOC_TYPE,
} from "@/graphql/OnboardingQueries";
import DragAndDropFiles from "@/components/custom-components/DragAndDropFiles.vue";
import moment from "moment";
import { scrollScreenToTop } from "../../../helper";

export default {
  name: "Training",

  props: {
    showSkip: {
      type: Boolean,
      default: false,
    },
  },

  components: { DragAndDropFiles },

  data: () => ({
    addTraining: [],
    startDateModal: false,
    endDateModal: false,
    subTypeList: [],
    minJoinDate: "",
    maxYear: "",
    saveLoading: false,
    trainingLoading: true,
    apiCallCount: 0,
    isFileLoading: false,
  }),

  apollo: {
    listDocumentSubType: {
      query: LIST_SUB_DOC_TYPE,
      client: "apolloClientA",
      variables() {
        return {
          Org_Code: localStorage.getItem("orgCode"),
          Url_Hash: localStorage.getItem("urlHash"),
          type_id: 6,
        };
      },
      result({ data }) {
        if (data && data.listDocumentSubType) {
          this.subTypeList = data.listDocumentSubType.documentSubType;
        }
      },
    },
    getTraining: {
      query: GET_TRAINING,
      client: "apolloClientA",
      fetchPolicy: "no-cache",
      variables() {
        return {
          Org_Code: localStorage.getItem("orgCode"),
          Url_Hash: localStorage.getItem("urlHash"),
        };
      },
      result({ data }) {
        if (data && data.getTraining) {
          const { training } = data.getTraining;
          if (training && training.length > 0) {
            let trainingList = [];
            for (let train of training) {
              let trainingOnj = {
                trainingId: train.Training_Id,
                fileName: train.File_Name ? train.File_Name.split("?")[3] : "",
                fileNameWithoutFormat: train.File_Name,
                trainingSubType: train.Sub_Type_Id ? train.Sub_Type_Id : 0,
                trainingName: train.Training_Name,
                startDate: train.Training_Start_Date,
                endDate: train.Training_End_Date,
                trainingCenter: train.Center,
                trainer: train.Trainer,
              };
              trainingList.push(trainingOnj);
            }
            this.addTraining = trainingList;
            this.$emit("training-done");
          } else {
            this.addTraining.push({
              trainingId: 0,
              fileName: "",
              fileNameWithoutFormat: "",
              trainingSubType: 0,
              trainingName: "",
              startDate: "",
              endDate: "",
              trainingCenter: "",
              trainer: "",
            });
          }
        }
        this.trainingLoading = false;
      },
      error() {
        this.trainingLoading = false;
      },
    },
  },

  computed: {
    formatDate() {
      return (date) => {
        return date ? moment(date).format("DD/MM/YYYY") : "";
      };
    },
  },

  mounted() {
    scrollScreenToTop();
  },

  created() {
    let storedDob = localStorage.getItem("DOB");
    if (storedDob && storedDob !== "null") {
      this.minJoinDate = moment(storedDob).format("YYYY-MM-DD");
    }
    this.maxYear = moment().format("YYYY-MM-DD");
  },

  methods: {
    updateFileName(fileName, index) {
      this.addTraining[index]["fileNameWithoutFormat"] = fileName;
    },

    addMoreTraining() {
      this.validateTraining().then((isValid) => {
        if (isValid) {
          this.addTraining.push({
            trainingId: 0,
            fileName: "",
            fileNameWithoutFormat: "",
            trainingSubType: 0,
            trainingName: "",
            startDate: "",
            endDate: "",
            trainingCenter: "",
            trainer: "",
          });
        }
      });
    },

    emptyEnd(index) {
      this.addTraining[index]["endDate"] = "";
      this.startDateModal = false;
    },

    validateTraining() {
      return new Promise((resolve) => {
        let callCount = 0,
          isValidCount = 0;
        for (let cIndex in this.addTraining) {
          this.$refs["trainingForm" + cIndex][0]
            .validate()
            .then((validationResponse) => {
              callCount += 1;
              if (validationResponse) {
                isValidCount += 1;
                if (callCount === this.addTraining.length) {
                  resolve(isValidCount === this.addTraining.length);
                }
              }
            });
        }
      });
    },

    onSaveTraining() {
      this.apiCallCount = 0;
      for (let training of this.addTraining) {
        this.validateTraining().then((isValid) => {
          if (isValid) {
            this.apiCallCount += 1;
            if (training.trainingId) {
              this.updateTrainingDetails(training);
            } else {
              this.addTrainingDetails(training);
            }
          }
        });
      }
    },

    onSkipTraining() {
      this.$emit("training-saved");
    },

    addTrainingDetails(trainingItem) {
      let vm = this;
      vm.saveLoading = true;
      vm.$apollo
        .mutate({
          mutation: ADD_TRAINING,
          variables: {
            Org_Code: localStorage.getItem("orgCode"),
            Url_Hash: localStorage.getItem("urlHash"),
            Training_Name: trainingItem.trainingName,
            Training_Start_Date: trainingItem.receivedOn,
            Training_End_Date: trainingItem.receivedFrom,
            Trainer: trainingItem.trainer,
            Center: trainingItem.trainingCenter,
            File_Name: trainingItem.fileNameWithoutFormat,
            Sub_Type_Id: trainingItem.trainingSubType,
          },
          client: "apolloClientA",
        })
        .then(() => {
          if (vm.apiCallCount === vm.addTraining.length) {
            vm.$emit("training-saved");
          }
        })
        .catch((addErr) => {
          let snackbarData = {
            isOpen: true,
            message:
              addErr.graphQLErrors.length !== 0
                ? addErr.graphQLErrors[0].message
                : "Something went wrong. It could be a possibility due to network connectivity error..",
            type: "warning",
          };
          vm.showAlert(snackbarData);
          if (vm.apiCallCount === vm.addTraining.length) {
            vm.saveLoading = false;
          }
        });
    },

    updateTrainingDetails(trainingItem) {
      let vm = this;
      vm.saveLoading = true;
      vm.$apollo
        .mutate({
          mutation: UPDATE_TRAINING,
          variables: {
            Org_Code: localStorage.getItem("orgCode"),
            Url_Hash: localStorage.getItem("urlHash"),
            Training_Id: trainingItem.trainingId,
            Training_Name: trainingItem.trainingName,
            Training_Start_Date: trainingItem.receivedOn,
            Training_End_Date: trainingItem.receivedFrom,
            Trainer: trainingItem.trainer,
            Center: trainingItem.trainingCenter,
            File_Name: trainingItem.fileNameWithoutFormat,
            Sub_Type_Id: trainingItem.trainingSubType,
          },
          client: "apolloClientA",
        })
        .then(() => {
          if (vm.apiCallCount === vm.addTraining.length) {
            vm.$emit("training-saved");
          }
        })
        .catch((addErr) => {
          let snackbarData = {
            isOpen: true,
            message:
              addErr.graphQLErrors.length !== 0
                ? addErr.graphQLErrors[0].message
                : "Something went wrong. It could be a possibility due to network connectivity error..",
            type: "warning",
          };
          vm.showAlert(snackbarData);
          if (vm.apiCallCount === vm.addTraining.length) {
            vm.saveLoading = false;
          }
        });
    },

    removeTraining(index, trainingItem) {
      if (trainingItem.trainingId) {
        let vm = this;
        this.$apollo
          .mutate({
            mutation: DELETE_TRAINING,
            variables: {
              Org_Code: localStorage.getItem("orgCode"),
              Url_Hash: localStorage.getItem("urlHash"),
              Training_Id: trainingItem.trainingId,
            },
            client: "apolloClientA",
          })
          .then(() => {
            let snackbarData = {
              isOpen: true,
              message: "Deleted Successfully",
              type: "success",
            };
            vm.showAlert(snackbarData);
            vm.addTraining.splice(index, 1);
            if (index === 0 && vm.addTraining.length === 0) {
              vm.addTraining.push({
                trainingId: 0,
                fileName: "",
                fileNameWithoutFormat: "",
                trainingSubType: 0,
                trainingName: "",
                startDate: "",
                endDate: "",
                trainingCenter: "",
                trainer: "",
              });
            }
          })
          .catch((deleteErr) => {
            let snackbarData = {
              isOpen: true,
              message:
                deleteErr.graphQLErrors.length !== 0
                  ? deleteErr.graphQLErrors[0].message
                  : "Something went wrong. It could be a possibility due to network connectivity error..",
              type: "warning",
            };
            vm.showAlert(snackbarData);
          });
      } else {
        this.addTraining.splice(index, 1);
      }
    },
    // show error or success message
    showAlert(snackbarData) {
      this.$store.commit("OPEN_SNACKBAR", snackbarData);
    },
  },
};
</script>
