<template>
  <div>
    <input
      ref="input"
      type="file"
      name="image"
      accept="image/*"
      @change="setImage"
    />
    <v-dialog
      v-if="showProfilePicUploadModal"
      v-model="showProfilePicUploadModal"
      class="overflow-hidden"
      :width="1000"
      @click:outside="showProfilePicUploadModal = false"
    >
      <v-card>
        <div
          class="d-flex"
          :class="
            showProfileAlter && photoPath
              ? ' justify-space-between'
              : 'justify-end'
          "
        >
          <v-icon
            v-if="showProfileAlter && photoPath"
            color="primary"
            size="20"
            class="ma-4"
            @click="showProfileAlter = false"
          >
            fa fa-chevron-left
          </v-icon>
          <v-icon
            color="primary"
            size="20"
            class="ma-4"
            @click="showProfilePicUploadModal = false"
          >
            close
          </v-icon>
        </div>
        <v-card-title class="mt-n12">
          <div class="text-primary text-center font-weight-medium">
            Profile Picture
          </div>
        </v-card-title>
        <div v-if="!showProfileAlter">
          <v-row class="pa-4">
            <v-col cols="12" class="text-center">
              <v-img
                :src="photoPath"
                :class="showProfileAlter ? '' : 'large-img'"
              ></v-img>
              <div class="d-flex justify-center py-6">
                <v-btn
                  class="mr-1 primary"
                  variant="outlined"
                  @click="showFileChooser"
                  ><v-icon class="pr-1 primary">fas fa-exchange-alt</v-icon
                  >Replace</v-btn
                >
                <v-btn
                  class="mr-1 primary"
                  variant="outlined"
                  @click="deleteProfile()"
                  ><v-icon class="pr-1 primary">fas fa-trash-alt</v-icon
                  >Delete</v-btn
                >
                <v-btn
                  class="mr-1 primary"
                  variant="outlined"
                  @click="showProfileAlter = true"
                  ><v-icon class="pr-1 primary">fas fa-pencil-alt</v-icon
                  >Alter</v-btn
                >
              </div>
            </v-col>
          </v-row>
        </div>
        <ProfilePictureUpload
          v-if="showProfileAlter"
          :profileImage="profileImage"
          @image-uploaded="onImageUpload($event)"
        ></ProfilePictureUpload>
      </v-card>
    </v-dialog>
    <div class="avatar-container">
      <div
        class="d-flex align-center rounded-circle ml-8"
        :class="isMobileView ? 'my-auto' : 'ma-5'"
      >
        <div
          v-if="profileLoading"
          class="avatar-with-shadow active-avatar"
          style="border-radius: 50%"
        >
          <v-skeleton-loader
            type="image"
            height="130px"
            width="130px"
            class="profile-loader"
            style="border-radius: 35%"
          ></v-skeleton-loader>
        </div>

        <img
          v-else-if="photoPath"
          :src="photoPath"
          class="avatar-with-shadow mt-n3"
          :class="
            empStatus === 'InActive' || empStatus === 'Rejected'
              ? 'inactive-avatar'
              : 'active-avatar'
          "
          style="border-radius: 50%; width: 130px; height: 130px"
        />

        <v-avatar
          v-else
          class="avatar-with-shadow pa-2 mb-4"
          :size="isMobileView ? avatarSize - 20 : avatarSize"
          color="grey-lighten-3"
          :class="
            empStatus === 'InActive' || empStatus === 'Rejected'
              ? 'inactive-avatar'
              : 'active-avatar'
          "
        >
          <v-icon size="70">fas fa-user-alt</v-icon>
        </v-avatar>
        <!-- <v-btn
          v-if="allowUpdate"
          size="small"
          class="mt-12 ml-n7 secondary"
          :icon="!photoPath ? 'fas fa-plus' : 'fas fa-pencil-alt'"
          @click="toggleShow"
        >
        </v-btn> -->
        <v-btn
          v-model="openProfileAction"
          color="primary"
          dark
          fab
          small
          class="mt-12 ml-n6"
          @click="toggleShow"
        >
          <v-icon v-if="openProfileAction"> mdi-close </v-icon>
          <v-icon v-else> mdi-camera </v-icon>
        </v-btn>
      </div>
    </div>
    <AppLoading v-if="isLoading"></AppLoading>
  </div>
</template>

<script>
import { base64ToFile } from "@/helper.js";
import ProfilePictureUpload from "./ProfilePictureUpload.vue";

export default {
  name: "ProfileAvatar",
  components: {
    ProfilePictureUpload,
  },
  props: {
    profileImagePath: {
      type: String,
      default: "",
    },
    fileName: {
      type: String,
      default: "",
    },
    avatarSize: {
      type: Number,
      required: false,
    },
    empStatus: {
      type: String,
      default: "green",
    },
    profileLoading: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["profile-uploaded", "profile-uploading"],
  data() {
    return {
      photoPath: "",
      photoFileName: "",
      showProfilePicUploadModal: false,
      isLoading: false,
      profileImage: "",
      showProfileAlter: false,
      openProfileAction: false,
    };
  },

  computed: {
    // to check the device is mobile based on window size
    isMobileView() {
      return this.$store.state.isMobileWindowSize;
    },
    orgCode() {
      let org_code = localStorage.getItem("orgCode");
      return org_code ? org_code : this.$store.getters.orgCode;
    },
    fileExtension() {
      if (this.photoFileName) {
        let fileEx = this.photoFileName.split(".");
        return fileEx && fileEx.length > 0 ? fileEx[1] : "";
      }
      return "";
    },
  },

  watch: {
    profileImagePath(val) {
      this.photoPath = val;
    },
    fileName(val) {
      this.photoFileName = val;
    },
    isLoading(val) {
      this.$emit("profile-uploading", val);
    },
  },

  mounted() {
    this.photoPath = this.profileImagePath;
    this.photoFileName = this.fileName;
  },

  methods: {
    async onImageUpload(params) {
      let vm = this;
      vm.isLoading = true;
      vm.showProfilePicUploadModal = false;
      vm.showProfileAlter = false;
      let stamp = Date.now();
      vm.photoFileName = stamp + "." + params[1];
      let fileContent = base64ToFile(params[0], vm.photoFileName);
      let filePath =
        "hrapp_upload/" + vm.orgCode + "_tmp/images/" + vm.photoFileName;
      await vm.$store
        .dispatch("s3FileUploadRetrieveAction", {
          fileName: filePath,
          action: "upload",
          type: "profile",
          fileContent: fileContent,
        })
        .then(() => {
          vm.photoPath = params[0];
          vm.$emit("profile-uploaded", {
            filePath: vm.photoFileName,
            action: "update",
          });
          vm.isLoading = false;
        })
        .catch(() => {
          vm.isLoading = false;
          let snackbarData = {
            isOpen: true,
            message:
              "Something went wrong while updating the profile picture. Please try after some time.",
            type: "warning",
          };
          vm.showAlert(snackbarData);
        });
    },

    toggleShow() {
      if (this.photoPath) {
        this.profileImage = this.photoPath;
        this.showProfilePicUploadModal = !this.showProfilePicUploadModal;
      } else {
        this.showFileChooser();
      }
    },

    async deleteProfile() {
      let vm = this;
      vm.isLoading = true;
      vm.showProfilePicUploadModal = false;
      vm.showProfileAlter = false;
      // let fileName = vm.employeeId + "." + vm.fileExtension;
      await vm.$store
        .dispatch("deleteImage", {
          fileName: this.photoFileName,
          type: "profile",
        })
        .then(() => {
          vm.photoPath = "";
          vm.isLoading = false;
          vm.$emit("profile-uploaded", {
            filePath: "",
            action: "delete",
          });
        })
        .catch(() => {
          vm.isLoading = false;
          let snackbarData = {
            isOpen: true,
            message:
              "Something went wrong while deleting the profile picture. Please try after some time.",
            type: "warning",
          };
          vm.showAlert(snackbarData);
        });
    },

    // show success/error message in snackbar
    showAlert(snackbarData) {
      this.$store.commit("OPEN_SNACKBAR", snackbarData);
    },
    setImage(e) {
      let file = "";
      if (e && e.target && e.target.files && e.target.files.length > 0) {
        file = e.target.files[0];
      }

      if (file) {
        if (file.type && file.type.indexOf("image/") === -1) {
          let snackbarData = {
            isOpen: true,
            message: "Please select an image file",
            type: "warning",
          };
          this.showAlert(snackbarData);
          return;
        }

        if (typeof FileReader === "function") {
          const reader = new FileReader();

          reader.onload = (event) => {
            this.profileImage = event.target.result;
            this.showProfilePicUploadModal = true;
            this.showProfileAlter = true;
          };

          reader.readAsDataURL(file);
        } else {
          let snackbarData = {
            isOpen: true,
            message:
              "Unable to read the image file. Please try after some time.",
            type: "warning",
          };
          this.showAlert(snackbarData);
        }
      }
    },
    showFileChooser() {
      this.$refs.input.click();
    },
  },
};
</script>

<style scoped>
.avatar-with-shadow {
  box-shadow: 0 0 8px 0 grey;
}

.active-avatar {
  border: 4px solid green;
}

.inactive-avatar {
  border: 4px solid red;
}
.avatar-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.large-img {
  height: 400px;
  width: 400px;
  margin-left: 250px;
}
.profile-loader > .v-skeleton-loader_bone {
  border-radius: 0 !important;
}
.profile-loader .v-skeleton-loader_image {
  border-radius: 0 !important;
}
</style>
