var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex justify-end mb-3"},[_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.addMoreEducation()}}},[_c('v-icon',[_vm._v("add")]),_vm._v("Add Education ")],1)],1),_vm._l((_vm.addEducation),function(education,index){return _c('v-form',{key:index,ref:'educationForm' + index,refInFor:true},[(_vm.addEducation.length > 1 || education.educationId)?_c('div',{staticClass:"d-flex justify-end mb-3"},[_c('v-btn',{attrs:{"color":"primary lighten-1","text":""},on:{"click":function($event){return _vm.removeEducation(index, education)}}},[_c('v-icon',[_vm._v("delete")]),_vm._v("Remove ")],1)],1):_vm._e(),_c('ValidationObserver',{ref:'educationForm' + index,refInFor:true},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pa-6",attrs:{"flat":""}},[_c('v-row',[(_vm.getFieldAlias(128).Field_Visiblity == 'Yes')?_c('v-col',{attrs:{"cols":"12","lg":"6","xl":"6","md":"6","sm":"6","xs":"12"}},[_c('ValidationProvider',{ref:'sclClgDipProvider' + index,refInFor:true,attrs:{"name":_vm.getFieldAlias(128).Field_Alias,"rules":_vm.getFieldAlias(128).Mandatory_Field == 'Yes'
                      ? 'required'
                      : ''},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-autocomplete',{attrs:{"id":'School/Dip/Clg',"outlined":"","items":_vm.courseList,"item-text":"Course_Name","item-value":"Course_Id","label":_vm.getFieldAlias(128).Field_Alias,"error-messages":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.getFieldAlias(128).Field_Alias)+" "),(_vm.getFieldAlias(128).Mandatory_Field == 'Yes')?_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]):_vm._e()]},proxy:true}],null,true),model:{value:(education.schoolDiplomaDeg),callback:function ($$v) {_vm.$set(education, "schoolDiplomaDeg", $$v)},expression:"education.schoolDiplomaDeg"}})]}}],null,true)})],1):_vm._e(),(_vm.getFieldAlias(129).Field_Visiblity == 'Yes')?_c('v-col',{attrs:{"cols":"12","lg":"6","xl":"6","md":"6","sm":"6","xs":"12"}},[_c('ValidationProvider',{ref:'specializationProvider' + index,refInFor:true,attrs:{"name":_vm.getFieldAlias(129).Field_Alias,"rules":_vm.getFieldAlias(129).Mandatory_Field == 'Yes'
                      ? 'required'
                      : ''},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [(_vm.getFieldAlias(129).Predefined == 'Yes')?_c('v-autocomplete',{attrs:{"id":'Specialization',"outlined":"","items":_vm.specializationList,"item-text":"Specialization","item-value":"Specialization_Id","label":_vm.getFieldAlias(129).Field_Alias,"error-messages":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.getFieldAlias(129).Field_Alias)+" "),(_vm.getFieldAlias(129).Mandatory_Field == 'Yes')?_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]):_vm._e()]},proxy:true}],null,true),model:{value:(education.specializationId),callback:function ($$v) {_vm.$set(education, "specializationId", $$v)},expression:"education.specializationId"}}):_c('v-text-field',{attrs:{"id":'Specialization',"outlined":"","error-messages":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.getFieldAlias(129).Field_Alias)+" "),(_vm.getFieldAlias(129).Mandatory_Field == 'Yes')?_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]):_vm._e()]},proxy:true}],null,true),model:{value:(education.specialization),callback:function ($$v) {_vm.$set(education, "specialization", $$v)},expression:"education.specialization"}})]}}],null,true)})],1):_vm._e(),(_vm.getFieldAlias(130).Field_Visiblity == 'Yes')?_c('v-col',{attrs:{"cols":"12","lg":"6","xl":"6","md":"6","sm":"6","xs":"12"}},[_c('ValidationProvider',{ref:'instituteProvider' + index,refInFor:true,attrs:{"name":_vm.getFieldAlias(130).Field_Alias,"rules":_vm.getFieldAlias(130).Mandatory_Field == 'Yes'
                      ? 'required'
                      : ''},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [(_vm.getFieldAlias(129).Predefined == 'Yes')?_c('v-autocomplete',{attrs:{"id":'InstituteName',"outlined":"","items":_vm.institutionList,"item-text":"Institution","item-value":"Institution_Id","label":_vm.getFieldAlias(130).Field_Alias,"error-messages":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.getFieldAlias(130).Field_Alias)+" "),(_vm.getFieldAlias(130).Mandatory_Field == 'Yes')?_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]):_vm._e()]},proxy:true}],null,true),model:{value:(education.instituteNameId),callback:function ($$v) {_vm.$set(education, "instituteNameId", $$v)},expression:"education.instituteNameId"}}):_c('v-text-field',{attrs:{"id":'InstituteName',"outlined":"","label":_vm.getFieldAlias(130).Field_Alias,"error-messages":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.getFieldAlias(130).Field_Alias)+" "),(_vm.getFieldAlias(130).Mandatory_Field == 'Yes')?_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]):_vm._e()]},proxy:true}],null,true),model:{value:(education.instituteName),callback:function ($$v) {_vm.$set(education, "instituteName", $$v)},expression:"education.instituteName"}})]}}],null,true)})],1):_vm._e(),(_vm.getFieldAlias(131).Field_Visiblity == 'Yes')?_c('v-col',{attrs:{"cols":"12","lg":"6","xl":"6","md":"6","sm":"6","xs":"12"}},[_c('ValidationProvider',{ref:'universityProvider' + index,refInFor:true,attrs:{"name":_vm.getFieldAlias(131).Field_Alias,"rules":_vm.getFieldAlias(131).Mandatory_Field == 'Yes'
                      ? 'required|multilingualNameNumericValidation'
                      : 'multilingualNameNumericValidation'},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"id":'InstituteName',"outlined":"","counter":50,"maxlength":50,"label":_vm.getFieldAlias(131).Field_Alias,"error-messages":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.getFieldAlias(131).Field_Alias)+" "),(_vm.getFieldAlias(131).Mandatory_Field == 'Yes')?_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]):_vm._e()]},proxy:true}],null,true),model:{value:(education.university),callback:function ($$v) {_vm.$set(education, "university", $$v)},expression:"education.university"}})]}}],null,true)})],1):_vm._e(),(_vm.getFieldAlias(156).Field_Visiblity == 'Yes')?_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"6","lg":"6","md":"6","xl":"6"}},[_c('ValidationProvider',{ref:'startDateProvider' + index,refInFor:true,attrs:{"name":"Start Date","rules":_vm.getFieldAlias(156).Mandatory_Field == 'Yes'
                      ? 'required'
                      : ''},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"id":'startDate',"type":"date","outlined":"","error-messages":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.getFieldAlias(156).Field_Name)+" "),(_vm.getFieldAlias(156).Mandatory_Field == 'Yes')?_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]):_vm._e()]},proxy:true}],null,true),model:{value:(education.startDate),callback:function ($$v) {_vm.$set(education, "startDate", $$v)},expression:"education.startDate"}})]}}],null,true)})],1):_vm._e(),(_vm.getFieldAlias(157).Field_Visiblity == 'Yes')?_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"6","lg":"6","md":"6","xl":"6"}},[_c('ValidationProvider',{ref:'endDateProvider' + index,refInFor:true,attrs:{"name":"End Date","rules":_vm.getFieldAlias(157).Mandatory_Field == 'Yes'
                      ? 'required'
                      : ''},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"id":'EndDate',"type":"date","outlined":"","error-messages":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.getFieldAlias(157).Field_Name)+" "),(_vm.getFieldAlias(157).Mandatory_Field == 'Yes')?_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]):_vm._e()]},proxy:true}],null,true),model:{value:(education.endDate),callback:function ($$v) {_vm.$set(education, "endDate", $$v)},expression:"education.endDate"}})]}}],null,true)})],1):_vm._e(),(_vm.getFieldAlias(158).Field_Visiblity == 'Yes')?_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"6","lg":"6","md":"6","xl":"6"}},[_c('ValidationProvider',{ref:'cCityProvider' + index,refInFor:true,attrs:{"name":"City","rules":_vm.getFieldAlias(158).Mandatory_Field == 'Yes'
                      ? 'required'
                      : ''},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [(_vm.getFieldAlias(158).Field_Visiblity == 'Yes')?_c('v-autocomplete',{attrs:{"id":'City',"items":_vm.cityStateList,"item-text":"cityStateDetails","item-value":"cityStateDetails","outlined":"","counter":50,"maxlength":50,"label":"City","error-messages":errors},on:{"change":function($event){return _vm.findStateCountry(index)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.getFieldAlias(158).Field_Name)+" "),(_vm.getFieldAlias(158).Mandatory_Field == 'Yes')?_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]):_vm._e()]},proxy:true}],null,true),model:{value:(education.city),callback:function ($$v) {_vm.$set(education, "city", $$v)},expression:"education.city"}}):_vm._e()]}}],null,true)})],1):_vm._e(),(_vm.getFieldAlias(159).Field_Visiblity == 'Yes')?_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"6","lg":"6","md":"6","xl":"6"}},[_c('ValidationProvider',{ref:'StateProvider' + index,refInFor:true,attrs:{"name":"State","rules":_vm.getFieldAlias(159).Mandatory_Field == 'Yes'
                      ? 'required'
                      : ''},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"id":'state',"outlined":"","readonly":education.state ? true : false,"label":"State","error-messages":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.getFieldAlias(159).Field_Name)+" "),(_vm.getFieldAlias(159).Mandatory_Field == 'Yes')?_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]):_vm._e()]},proxy:true}],null,true),model:{value:(education.state),callback:function ($$v) {_vm.$set(education, "state", $$v)},expression:"education.state"}})]}}],null,true)})],1):_vm._e(),(_vm.getFieldAlias(160).Field_Visiblity == 'Yes')?_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"6","lg":"6","md":"6","xl":"6"}},[_c('ValidationProvider',{ref:'CountryProvider' + index,refInFor:true,attrs:{"name":"Country","rules":_vm.getFieldAlias(160).Mandatory_Field == 'Yes'
                      ? 'required'
                      : ''},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-autocomplete',{attrs:{"id":'Country',"items":_vm.countryList,"item-text":"Country_Name","item-value":"Country_Code","outlined":"","readonly":education.country ? true : false,"label":"Country*","error-messages":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.getFieldAlias(160).Field_Name)+" "),(_vm.getFieldAlias(160).Mandatory_Field == 'Yes')?_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]):_vm._e()]},proxy:true}],null,true),model:{value:(education.country),callback:function ($$v) {_vm.$set(education, "country", $$v)},expression:"education.country"}})]}}],null,true)})],1):_vm._e(),(_vm.getFieldAlias(292).Field_Visiblity == 'Yes')?_c('v-col',{attrs:{"cols":"12","lg":"6","xl":"6","md":"6","sm":"6","xs":"12"}},[_c('ValidationProvider',{ref:'yearOfStartProvider' + index,refInFor:true,attrs:{"name":_vm.getFieldAlias(292).Field_Alias,"rules":_vm.getFieldAlias(292).Mandatory_Field == 'Yes'
                      ? 'required'
                      : ''},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [(_vm.getFieldAlias(292).Predefined == 'Yes')?_c('v-autocomplete',{attrs:{"id":'YearOfStart',"outlined":"","items":_vm.startYearList,"label":_vm.getFieldAlias(292).Field_Alias,"error-messages":errors},on:{"change":function($event){education.yearOfPassing = null}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.getFieldAlias(292).Field_Alias)+" "),(_vm.getFieldAlias(292).Mandatory_Field == 'Yes')?_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]):_vm._e()]},proxy:true}],null,true),model:{value:(education.yearOfStart),callback:function ($$v) {_vm.$set(education, "yearOfStart", $$v)},expression:"education.yearOfStart"}}):_c('v-text-field',{attrs:{"id":'YearOfStart',"outlined":"","error-messages":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.getFieldAlias(292).Field_Alias)+" "),(_vm.getFieldAlias(292).Mandatory_Field == 'Yes')?_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]):_vm._e()]},proxy:true}],null,true),model:{value:(education.yearOfStart),callback:function ($$v) {_vm.$set(education, "yearOfStart", $$v)},expression:"education.yearOfStart"}})]}}],null,true)})],1):_vm._e(),(_vm.getFieldAlias(134).Field_Visiblity == 'Yes')?_c('v-col',{attrs:{"cols":"12","lg":"6","xl":"6","md":"6","sm":"6","xs":"12"}},[_c('ValidationProvider',{ref:'yearOfPassingProvider' + index,refInFor:true,attrs:{"name":_vm.getFieldAlias(134).Field_Alias,"rules":_vm.getFieldAlias(134).Mandatory_Field == 'Yes'
                      ? 'required'
                      : ''},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-autocomplete',{attrs:{"id":'YearOfPassing',"outlined":"","items":_vm.endYearList(index),"label":_vm.getFieldAlias(134).Field_Alias,"error-messages":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.getFieldAlias(134).Field_Alias)+" "),(_vm.getFieldAlias(134).Mandatory_Field == 'Yes')?_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]):_vm._e()]},proxy:true}],null,true),model:{value:(education.yearOfPassing),callback:function ($$v) {_vm.$set(education, "yearOfPassing", $$v)},expression:"education.yearOfPassing"}})]}}],null,true)})],1):_vm._e(),(_vm.getFieldAlias(132).Field_Visiblity == 'Yes')?_c('v-col',{attrs:{"cols":"12","lg":"6","xl":"6","md":"6","sm":"6","xs":"12"}},[_c('ValidationProvider',{ref:'percentageProvider' + index,refInFor:true,attrs:{"name":_vm.getFieldAlias(132).Field_Alias,"rules":_vm.getFieldAlias(132).Mandatory_Field == 'Yes'
                      ? 'numeric|minimum:0|maximum:100|required'
                      : 'numeric|minimum:0|maximum:100'},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"id":'Percentage',"type":"number","outlined":"","label":_vm.getFieldAlias(132).Field_Alias,"error-messages":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.getFieldAlias(132).Field_Alias)+" "),(_vm.getFieldAlias(132).Mandatory_Field == 'Yes')?_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]):_vm._e()]},proxy:true}],null,true),model:{value:(education.percentage),callback:function ($$v) {_vm.$set(education, "percentage", $$v)},expression:"education.percentage"}})]}}],null,true)})],1):_vm._e(),(_vm.getFieldAlias(133).Field_Visiblity == 'Yes')?_c('v-col',{attrs:{"cols":"12","lg":"6","xl":"6","md":"6","sm":"6","xs":"12"}},[_c('ValidationProvider',{ref:'gradeProvider' + index,refInFor:true,attrs:{"name":_vm.getFieldAlias(133).Field_Alias,"rules":_vm.getFieldAlias(133).Mandatory_Field == 'Yes'
                      ? 'required|alphaNumeric'
                      : 'alphaNumeric'},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"id":'Grade',"outlined":"","counter":10,"maxlength":10,"label":_vm.getFieldAlias(133).Field_Alias,"error-messages":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.getFieldAlias(133).Field_Alias)+" "),(_vm.getFieldAlias(133).Mandatory == 'Yes')?_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]):_vm._e()]},proxy:true}],null,true),model:{value:(education.grade),callback:function ($$v) {_vm.$set(education, "grade", $$v)},expression:"education.grade"}})]}}],null,true)})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","lg":"6","xl":"6","md":"6","sm":"6","xs":"12"}},[_c('DragAndDropFiles',{attrs:{"id":'edu' + index,"file-category":"document","key-index":'edu-file-upload-' + index,"old-file-name":education.fileName,"Mandatory":true,"is-error":education.fileValidationError,"message":true},on:{"file-event-success":function($event){return _vm.updateFileName($event, index)},"file-event-triggered":function($event){_vm.isFileLoading = $event}}})],1),(education.fileNameWithoutFormat)?_c('v-col',{attrs:{"cols":"12","lg":"6","xl":"6","md":"6","sm":"6","xs":"12"}},[_c('ValidationProvider',{ref:'eSubTypeProvider' + index,refInFor:true,attrs:{"name":"Document Sub Type","rules":education.fileNameWithoutFormat.length > 0
                      ? 'required'
                      : ''},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-autocomplete',{attrs:{"id":'DocumentSubType',"outlined":"","items":_vm.subTypeList,"item-text":"Document_Sub_Type","item-value":"Document_Sub_Type_Id","error-messages":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Document Sub Type "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]},proxy:true}],null,true),model:{value:(education.educationSubType),callback:function ($$v) {_vm.$set(education, "educationSubType", $$v)},expression:"education.educationSubType"}})]}}],null,true)})],1):_vm._e()],1)],1)],1)],1)],1),_c('v-divider')],1)}),_c('div',{staticClass:"mt-3 d-flex justify-end"},[_c('v-btn',{staticStyle:{"border-radius":"10px"},attrs:{"color":"primary","large":"","loading":_vm.saveLoading,"disabled":_vm.isFileLoading},on:{"click":function($event){return _vm.onSaveEducation()}}},[_vm._v("Save & Continue")]),(_vm.isEducationMandatory === 'No')?_c('v-btn',{staticClass:"ml-2",staticStyle:{"border-radius":"10px"},attrs:{"elevation":"2","text":"","color":"primary","large":""},on:{"click":function($event){return _vm.onSkipEducation()}}},[_vm._v("Skip")]):_vm._e()],1),_c('v-overlay',{attrs:{"absolute":"","value":_vm.eduLoading || _vm.saveLoading,"color":"#fff","z-index":"1"}},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"50"}})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }