<template>
  <div>
    <div class="full-height">
      <v-row justify="center" style="height: 90%">
        <v-col cols>
          <div class="d-flex justify-end">
            <v-btn color="primary" text @click="addMoreAccreditations()">
              <v-icon>add</v-icon>Add {{ getFieldAlias(230).Field_Alias }}
            </v-btn>
          </div>
          <v-form
            v-for="(accreditation, index) in addAccreditations"
            :key="index"
            :ref="'accreditationForm' + index"
          >
            <div
              v-if="
                (addAccreditations.length > 1 ||
                  accreditation.accreditationsId) &&
                accreditation.Mandatory == 'No'
              "
              class="d-flex justify-end mb-3"
            >
              <v-btn
                color="primary lighten-1"
                text
                @click="removeAccreditations(index, accreditation)"
              >
                <v-icon>delete</v-icon>Remove
              </v-btn>
            </div>
            <ValidationObserver :ref="'accreditationsForm' + index">
              <v-row>
                <v-col cols="12">
                  <v-card flat class="pa-6">
                    <v-row>
                      <!-- <v-col cols="4">
                            <ValidationProvider
                              ref="accreditationsProvider"
                              v-slot="{ errors }"
                              name="Accreditations"
                              rules="required"
                            >
                              <v-select
                                :id="'Accreditations'"
                                v-model="accreditation.accreditations"
                                outlined
                                :items="accreditationsList"
                                :error-messages="errors"
                                label="Accreditations*"
                              ></v-select>
                            </ValidationProvider>
                      </v-col>-->
                      <v-col cols="12" lg="4" xl="4" md="6" sm="12" xs="12">
                        <ValidationProvider
                          :ref="'accreditationCategoryTypeProvider' + index"
                          v-slot="{ errors }"
                          name="Accreditation Category Type"
                          :rules="'required'"
                        >
                          <v-autocomplete
                            v-if="accreditation.Mandatory == 'No'"
                            :id="'Accreditation Category Type Id'"
                            v-model="accreditation.accreditationAndType"
                            outlined
                            :items="accreditationsTypeList"
                            :error-messages="errors"
                            :label="getFieldAlias(230).Field_Alias"
                            item-text="accreditationType"
                            item-value="accreditationCategoryAndTypeId"
                            :search-input.sync="accreditationSearch[index]"
                            @change="onFieldChange()"
                          >
                            <template #item="{ item }">
                              <div class="d-flex flex-column">
                                <div class="mt-2">
                                  {{ item.accreditationCategory }}
                                </div>
                                <div class="grey--text">
                                  {{ item.accreditationType }}
                                </div>
                              </div>
                            </template>
                          </v-autocomplete>
                          <v-text-field
                            v-else
                            :label="getFieldAlias(230).Field_Alias"
                            :id="'Accreditation Category Type'"
                            v-model="accreditation.accreditationType"
                            disabled
                            outlined
                            :error-messages="errors"
                          ></v-text-field>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" lg="4" xl="4" md="6" sm="12" xs="12">
                        <ValidationProvider
                          ref="identifierProvider"
                          v-slot="{ errors }"
                          name="Identifier"
                          rules="required|alphaNumSpaceWithTwoSymbol"
                        >
                          <v-text-field
                            :id="'identifier'"
                            v-model="accreditation.identifier"
                            outlined
                            :counter="50"
                            label="Identifier"
                            :error-messages="errors"
                            @input="onFieldChange()"
                          ></v-text-field>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" lg="4" xl="4" md="6" sm="12" xs="12">
                        <v-dialog
                          v-model="receivedDateModal[index]"
                          width="290"
                        >
                          <template #activator="{ on }">
                            <ValidationProvider
                              ref="receivedDateProvider"
                              v-slot="{ errors }"
                              name="Received Date"
                              rules="required"
                            >
                              <v-text-field
                                :id="'AccreditationsFrom'"
                                :value="formatDate(accreditation.receivedDate)"
                                outlined
                                readonly
                                label="Received Date"
                                v-on="on"
                                :error-messages="errors"
                                @input="onFieldChange()"
                              ></v-text-field>
                            </ValidationProvider>
                          </template>
                          <v-date-picker
                            v-model="accreditation.receivedDate"
                            :min="minJoinDate"
                            :max="maxYear"
                            @change="emptyExpiryDate(index)"
                          ></v-date-picker>
                        </v-dialog>
                      </v-col>
                      <v-col cols="12" lg="4" xl="4" md="6" sm="12" xs="12">
                        <v-dialog v-model="expiryDateModal[index]" width="290">
                          <template #activator="{ on }">
                            <ValidationProvider
                              ref="expiryDateProvider"
                              v-slot="{ errors }"
                              name="Expiry Date"
                              rules="required"
                            >
                              <v-text-field
                                :id="'AccreditationsTo'"
                                :value="formatDate(accreditation.expiryDate)"
                                readonly
                                outlined
                                :disabled="!accreditation.receivedDate"
                                label="Expiry Date"
                                v-on="on"
                                :error-messages="errors"
                                @input="onFieldChange()"
                              ></v-text-field>
                            </ValidationProvider>
                          </template>
                          <v-date-picker
                            v-model="accreditation.expiryDate"
                            :min="accreditation.receivedDate"
                            @change="expiryDateModal[index] = false"
                          ></v-date-picker>
                        </v-dialog>
                      </v-col>
                      <v-col cols="12" lg="4" xl="4" md="6" sm="12" xs="12">
                        <DragAndDropFiles
                          :id="'accreditation' + index"
                          file-category="accreditation"
                          :key-index="'accreditation-file-upload-' + index"
                          :old-file-name="accreditation.fileName"
                          :is-error="accreditation.fileValidationError"
                          @file-event-success="updateFileName($event, index)"
                          @file-event-triggered="isFileLoading = $event"
                          :message="true"
                        ></DragAndDropFiles>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </ValidationObserver>
            <v-divider></v-divider>
          </v-form>
          <v-overlay
            absolute
            :value="accreditationLoading || saveLoading"
            color="#fff"
            z-index="1"
          >
            <v-progress-circular
              color="primary"
              indeterminate
              size="50"
            ></v-progress-circular>
          </v-overlay>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import {
  GET_ACCREDITATIONS,
  ADD_ACCREDITATIONS,
  UPDATE_ACCREDITATIONS,
  LIST_ACCREDITATIONS_AND_TYPES,
  DELETE_ACCREDITATIONS,
  GET_FORM_FIELDS_AND_TYPE,
} from "@/graphql/OnboardingQueries";
import DragAndDropFiles from "@/components/custom-components/DragAndDropFiles.vue";
import moment from "moment";
import { scrollScreenToTop } from "../../../helper";

export default {
  name: "SkillProfiles",

  components: { DragAndDropFiles },

  data: () => ({
    addAccreditations: [],
    accreditationsList: [],
    accreditationsTypeList: [],
    receivedDateModal: [],
    expiryDateModal: [],
    maxYear: "",
    minJoinDate: "",
    saveLoading: false,
    apiCallCount: 0,
    accreditationLoading: true,
    showSkip: false,
    isFileLoading: false,
    accreditationSearch: [],
    mandatoryList: [],
    formList: [],
  }),

  created() {
    let storedDob = localStorage.getItem("DOB");
    if (storedDob && storedDob !== "null") {
      this.minJoinDate = moment(storedDob).format("YYYY-MM-DD");
    }
    this.maxYear = moment().format("YYYY-MM-DD");
  },

  apollo: {},

  computed: {
    formatDate() {
      return (date) => {
        return date ? moment(date).format("DD/MM/YYYY") : "";
      };
    },
    isAustralian() {
      let country = localStorage.getItem("country");
      if (country && country.toLowerCase() === "au") {
        return true;
      }
      return false;
    },
  },

  // watch: {
  //   "accreditation.accreditationAndType": function () {
  //     this.accreditationSearch = "";
  //   },
  // },

  mounted() {
    scrollScreenToTop();
    if (!this.isAustralian) {
      this.showSkip = true;
    }
    this.retrieveAccreditationAndType();
    this.getFieldProps();
  },

  methods: {
    onFieldChange() {
      this.$emit("form-dirty");
    },
    getFieldAlias(fieldId) {
      return this.formList.find((field) => field.Field_Id === fieldId) || {};
    },
    getFieldProps() {
      let vm = this;
      vm.saveLoading = true;
      this.$apollo
        .query({
          query: GET_FORM_FIELDS_AND_TYPE,
          variables: {
            form_Id: 178,
          },
          client: "apolloClientB",
        })
        .then((res) => {
          if (res.data.getFormFeildsByFormIdAndTab) {
            this.formList = res.data.getFormFeildsByFormIdAndTab.formFields;
          }
          vm.saveLoading = false;
        })
        .catch(() => {
          vm.saveLoading = false;
        });
    },
    retrieveAccreditationAndType() {
      let vm = this;
      vm.$apollo
        .query({
          query: LIST_ACCREDITATIONS_AND_TYPES,
          client: "apolloClientB",
          variables: {
            urlHash: localStorage.getItem("urlHash"),
          },
        })
        .then((data) => {
          data = data.data;
          if (data && data.retrieveAccreditationAndType) {
            this.accreditationsTypeList =
              data.retrieveAccreditationAndType.accreditationAndType;
            this.accreditationsTypeList.filter((e) => {
              if (e.Mandatory == "Yes") {
                this.mandatoryList.push({
                  ...e,
                  receivedDate: "",
                  expiryDate: "",
                  fileName: "",
                });
              }
            });
          }
          this.retrieveAccreditationDetails();
        });
    },
    retrieveAccreditationDetails() {
      let vm = this;
      vm.$apollo
        .query({
          query: GET_ACCREDITATIONS,
          client: "apolloClientB",
          fetchPolicy: "no-cache",
          variables: {
            urlHash: localStorage.getItem("urlHash"),
          },
        })
        .then((data) => {
          data = data.data;
          if (data && data.retrieveAccreditationDetails) {
            const { accreditationDetails } = data.retrieveAccreditationDetails;
            if (accreditationDetails && accreditationDetails.length > 0) {
              let accreditationsList = [];
              for (let accreditation of accreditationDetails) {
                let accreditationsOnj = {
                  accreditationType: accreditation.accreditationType,
                  accreditationAndType:
                    accreditation.accreditationCategoryAndTypeId,
                  identifier: accreditation.identifier,
                  receivedDate: accreditation.receivedDate,
                  expiryDate: accreditation.expiryDate,
                  accreditationsId: accreditation.accreditationDetailId,
                  fileName: accreditation.fileName
                    ? accreditation.fileName.split("?")[3]
                    : "",
                  fileNameWithoutFormat: accreditation.fileName,
                  fileValidationError: "",
                  Mandatory: accreditation.Mandatory,
                };
                accreditationsList.push(accreditationsOnj);
              }
              this.addAccreditations = [...accreditationsList];
              this.showSkip = true;
            } else if (this.mandatoryList.length > 0) {
              this.showSkip = false;
              this.mandatoryList.map((e) => {
                this.addAccreditations.push({
                  ...e,
                  identifier: "",
                  receivedDate: "",
                  expiryDate: "",
                  fileName: "",
                  fileNameWithoutFormat: "",
                  accreditationsId: 0,
                  fileValidationError: "",
                });
              });
            } else {
              this.addAccreditations.push({
                accreditationAndType: 0,
                identifier: "",
                receivedDate: "",
                expiryDate: "",
                fileName: "",
                fileNameWithoutFormat: "",
                accreditationsId: 0,
                fileValidationError: "",
                Mandatory: "No",
              });
            }
          }
          this.accreditationLoading = false;
        })
        .catch(() => {
          this.accreditationLoading = false;
        });
    },
    accreditationInfoDetails() {
      let isValid = this.validateAccreditations();
      if (isValid) {
        return this.addAccreditations;
      }
    },
    updateFileName(fileName, index) {
      this.addAccreditations[index]["fileNameWithoutFormat"] = fileName;
      this.addAccreditations[index]["fileValidationError"] = "";
      this.onFieldChange();
    },

    onClickPrevBtn() {
      this.$emit("back-to-prev");
    },

    addMoreAccreditations() {
      this.validateAccreditations().then((isValid) => {
        if (isValid) {
          this.addAccreditations.push({
            accreditationAndType: 0,
            identifier: "",
            receivedDate: "",
            expiryDate: "",
            fileName: "",
            fileNameWithoutFormat: "",
            fileValidationError: "",
            accreditationsId: 0,
            Mandatory: "No",
          });
        }
      });
    },

    emptyExpiryDate(index) {
      this.addAccreditations[index]["expiryDate"] = "";
      this.receivedDateModal[index] = false;
    },

    validateAccreditations() {
      return new Promise((resolve) => {
        let callCount = 0,
          isValidCount = 0;
        for (let aIndex in this.addAccreditations) {
          this.$refs["accreditationsForm" + aIndex][0]
            .validate()
            .then((validationResponse) => {
              callCount += 1;
              let aIndexFileName =
                this.addAccreditations[aIndex]["fileNameWithoutFormat"];
              if (validationResponse && aIndexFileName) {
                isValidCount += 1;
                if (callCount === this.addAccreditations.length) {
                  resolve(isValidCount === this.addAccreditations.length);
                }
              } else if (!aIndexFileName) {
                this.addAccreditations[aIndex]["fileValidationError"] =
                  "Document is required";
                resolve(false);
              }
            });
        }
      });
    },

    onSaveAccreditations() {
      this.apiCallCount = 0;
      for (let accreditation of this.addAccreditations) {
        this.validateAccreditations().then((isValid) => {
          if (isValid) {
            this.apiCallCount += 1;
            if (accreditation.accreditationsId) {
              this.updateAccreditationsDetails(accreditation);
            } else {
              this.addAccreditationsDetails(accreditation);
            }
          }
        });
      }
    },

    onSkipAccreditation() {
      this.$emit("accreditations-saved");
    },

    addAccreditationsDetails(accreditationsItem) {
      let vm = this;
      vm.saveLoading = true;
      vm.$apollo
        .mutate({
          mutation: ADD_ACCREDITATIONS,
          variables: {
            urlHash: localStorage.getItem("urlHash"),
            accreditationCategoryAndTypeId:
              accreditationsItem.accreditationAndType
                ? accreditationsItem.accreditationAndType
                : accreditationsItem.accreditationCategoryAndTypeId,
            identifier: accreditationsItem.identifier,
            receivedDate: accreditationsItem.receivedDate,
            expiryDate: accreditationsItem.expiryDate,
            fileName: accreditationsItem.fileNameWithoutFormat,
          },
          client: "apolloClientC",
        })
        .then(() => {
          if (vm.apiCallCount === vm.addAccreditations.length) {
            vm.$emit("accreditations-saved");
          }
        })
        .catch((addErr) => {
          let snackbarData = {
            isOpen: true,
            message:
              addErr.graphQLErrors.length !== 0
                ? addErr.graphQLErrors[0].message
                : "Something went wrong. It could be a possibility due to network connectivity error..",
            type: "warning",
          };
          vm.showAlert(snackbarData);
          if (vm.apiCallCount === vm.addAccreditations.length) {
            vm.saveLoading = false;
          }
        });
    },

    updateAccreditationsDetails(accreditationsItem) {
      let vm = this;
      vm.saveLoading = true;
      vm.$apollo
        .mutate({
          mutation: UPDATE_ACCREDITATIONS,
          variables: {
            urlHash: localStorage.getItem("urlHash"),
            accreditationCategoryAndTypeId:
              accreditationsItem.accreditationAndType,
            identifier: accreditationsItem.identifier,
            receivedDate: accreditationsItem.receivedDate,
            expiryDate: accreditationsItem.expiryDate,
            fileName: accreditationsItem.fileNameWithoutFormat,
            accreditationDetailId: accreditationsItem.accreditationsId,
          },
          client: "apolloClientC",
        })
        .then(() => {
          if (vm.apiCallCount === vm.addAccreditations.length) {
            vm.$emit("accreditations-saved");
          }
        })
        .catch((addErr) => {
          let snackbarData = {
            isOpen: true,
            message:
              addErr.graphQLErrors.length !== 0
                ? addErr.graphQLErrors[0].message
                : "Something went wrong. It could be a possibility due to network connectivity error..",
            type: "warning",
          };
          vm.showAlert(snackbarData);
          if (vm.apiCallCount === vm.addAccreditations.length) {
            vm.saveLoading = false;
          }
        });
    },

    removeAccreditations(index, accreditationsItem) {
      if (accreditationsItem.accreditationsId) {
        let vm = this;
        this.$apollo
          .mutate({
            mutation: DELETE_ACCREDITATIONS,
            variables: {
              urlHash: localStorage.getItem("urlHash"),
              accreditationDetailId: accreditationsItem.accreditationsId,
            },
            client: "apolloClientC",
          })
          .then(() => {
            let snackbarData = {
              isOpen: true,
              message: "Deleted Successfully",
              type: "success",
            };
            vm.showAlert(snackbarData);
            vm.addAccreditations.splice(index, 1);
            if (index === 0 && vm.addAccreditations.length === 0) {
              vm.addAccreditations.push({
                accreditationAndType: "",
                identifier: "",
                receivedDate: "",
                expiryDate: "",
                fileName: "",
                fileNameWithoutFormat: "",
                fileValidationError: "",
                accreditationsId: 0,
                Mandatory: "No",
              });
              if (vm.isAustralian) {
                vm.showSkip = false;
              }
            }
          })
          .catch((deleteErr) => {
            let snackbarData = {
              isOpen: true,
              message:
                deleteErr.graphQLErrors.length !== 0
                  ? deleteErr.graphQLErrors[0].message
                  : "Something went wrong. It could be a possibility due to network connectivity error..",
              type: "warning",
            };
            vm.showAlert(snackbarData);
          });
      } else {
        this.addAccreditations.splice(index, 1);
      }
    },

    // show error or success message
    showAlert(snackbarData) {
      this.$store.commit("OPEN_SNACKBAR", snackbarData);
    },
  },
};
</script>
