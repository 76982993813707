<template>
  <div v-if="isMounted" class="full-height">
    <LayoutCheck
      v-if="!isValidLayout"
      calledFrom="auth"
      @valid-layout="isValidLayout = true"
    ></LayoutCheck>
    <div v-else-if="!isFormCompleted" class="full-height">
      <v-app-bar app color="white" dark height="50">
        <v-app-bar-nav-icon class="primary--text"></v-app-bar-nav-icon>

        <v-toolbar-title class="top-text primary--text">
          Self Onboarding
        </v-toolbar-title>

        <v-spacer></v-spacer>
        <div class="primary--text" @click="signOutUser()">
          <v-icon size="35" color="primary">account_circle</v-icon>
          <span class="mx-2">Sign Out</span>
          <v-icon color="primary">logout</v-icon>
        </div>
      </v-app-bar>
      <Onboarding @form-submitted="isFormCompleted = true"></Onboarding>
    </div>
    <div v-else class="full-height">
      <v-row justify="center" class="full-height">
        <v-col
          cols="12"
          sm="10"
          class="justify-center d-flex align-center"
          :class="isMobileView ? 'flex-column text-center' : ''"
        >
          <v-icon size="100" color="green">check_circle</v-icon>
          <div class="pl-4">
            <div class="text-h4 primary--text">
              You have successfully completed the onboarding
            </div>
            <div class="grey--text text--darken-1 text-h5 mt-3">
              We have sent it for review
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import LayoutCheck from "./LayoutCheck.vue";
import Onboarding from "../views/onboarding/Onboarding.vue";

export default {
  name: "AuthenticateLayout",

  components: { LayoutCheck, Onboarding },

  data: () => ({
    isMounted: false,
    isValidLayout: false,
    isFormCompleted: false,
  }),

  mounted() {
    let orgCode = localStorage.getItem("orgCode");
    let urlHash = localStorage.getItem("urlHash");
    let authorization = localStorage.getItem("authorization");
    if (orgCode && urlHash && authorization) {
      this.isMounted = true;
    } else {
      this.$store.dispatch("logoutUser");
    }
  },

  computed: {
    // to check the device is mobile based on window size
    isMobileView() {
      return this.$store.state.isMobileWindowSize;
    },
  },

  methods: {
    signOutUser() {
      this.$store.dispatch("logoutUser");
    },
  },
};
</script>

<style lang="css">
.top-text {
  font-size: 25px;
  font-weight: medium;
}
</style>
