<template>
  <div class="full-height">
    <v-row justify="center" style="height: 90%">
      <v-col cols="11">
        <v-row class="full-height my-6" style="height: 100%">
          <v-col v-if="windowWidth > 960" cols="3">
            <v-card class="pa-4 text-center" raised height="100%" width="100%">
              <ProfileAvatar
                :profileLoading="profileLoading"
                :avatarSize="150"
                :profileImagePath="this.photoPath"
                :fileName="fileName"
                @profile-uploaded="updateProfile($event)"
              >
              </ProfileAvatar>
              <v-card
                v-for="(detail, index) in basicDetails"
                :key="detail.type"
                class="d-flex my-3 pa-3"
                min-height="50"
                :color="index === selectedInfo ? 'primary' : ''"
                @click="onClickNextSection(detail)"
              >
                <v-icon :color="index === selectedInfo ? 'white' : 'primary'">{{
                  detail.icon
                }}</v-icon>
                <div
                  class="pl-2"
                  :class="
                    index === selectedInfo ? 'white--text' : 'primary--text'
                  "
                >
                  {{ detail.heading }}
                </div>
              </v-card>
            </v-card>
          </v-col>
          <v-col cols="12" lg="9" md="9" sm="12" xl="9">
            <v-card raised height="100%" width="100%" class="pa-3">
              <div v-if="!personalInfoLoading">
                <div class="d-flex">
                  <v-icon
                    v-if="selectedInfo > 0"
                    color="primary"
                    @click="onClickPrevBtn()"
                    >arrow_back</v-icon
                  >
                  <div class="pl-2 text-h5 primary--text font-weight-medium">
                    {{ stepHeading() }}
                  </div>
                </div>
                <BasicInformation
                  ref="basicInfo"
                  v-if="selectedInfo === 0"
                  :personal-info="personalInfoDetails"
                  @relationship-list="relationshipList = $event"
                ></BasicInformation>
                <AddressInfo
                  ref="addressInfo"
                  v-else-if="selectedInfo === 1 && !personalInfoLoading"
                  :personal-info="personalInfoDetails"
                >
                </AddressInfo>
                <DependentInfo
                  ref="dependentInfo"
                  v-else-if="selectedInfo === 2"
                  :relationship-list="relationshipList"
                  @dependent-saved="onSavedDependent()"
                ></DependentInfo>
                <v-divider v-if="selectedInfo !== 2"></v-divider>
                <div v-if="selectedInfo !== 2" class="mt-3 d-flex justify-end">
                  <v-btn
                    color="primary"
                    style="border-radius: 10px"
                    large
                    :loading="saveLoading"
                    @click="onSaveBasicInfo()"
                    >Save & Continue</v-btn
                  >
                </div>
              </div>
              <v-overlay
                absolute
                :value="personalInfoLoading || saveLoading"
                color="#fff"
                z-index="1"
              >
                <v-progress-circular
                  color="primary"
                  indeterminate
                  size="50"
                ></v-progress-circular>
              </v-overlay>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BasicInformation from "./BasicInformation.vue";
import ProfileAvatar from "../../../components/custom-components/ProfileAvatar.vue";
import {
  GET_PERSONAL_INFO,
  UPDATE_PERSONAL_INFO,
  UPDATE_PHOTO_PATH,
  GET_CITY_LIST_WITH_STATE,
} from "@/graphql/OnboardingQueries";
import AddressInfo from "./AddressInformation.vue";
import DependentInfo from "./DependentInformation.vue";

export default {
  name: "PersonalDetails",

  components: {
    BasicInformation,
    AddressInfo,
    DependentInfo,
    ProfileAvatar,
  },

  data: () => ({
    openProfileAction: false,
    profileLoading: true,
    photoPath: "",
    fileName: "",
    newFileName: "",
    show: false,
    personalInfoDetails: {},
    relationshipList: [],
    personalInfoLoading: true,
    selectedInfo: 0,
    step1Details: {},
    step2Details: {},
    step3Details: {},
    saveLoading: false,
    basicDetails: [
      {
        heading: "Basic Information",
        type: "basic",
        icon: "notes",
        value: 0,
      },
      {
        heading: "Address",
        type: "address",
        icon: "pin_drop",
        value: 1,
      },
      {
        heading: "Dependent Details",
        type: "dependent",
        icon: "group",
        value: 2,
      },
    ],
    cityStateList: [],
  }),

  computed: {
    // current window size
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    // to check the device is mobile based on window size
    isMobileView() {
      return this.$store.state.isMobileWindowSize;
    },
  },

  apollo: {
    getCityListWithState: {
      query: GET_CITY_LIST_WITH_STATE,
      client: "apolloClientB",
      result({ data }) {
        this.cityStateList = data.getCityListWithState.cityDetails;
      },
    },
    getPersonalInfo: {
      query: GET_PERSONAL_INFO,
      client: "apolloClientA",
      fetchPolicy: "no-cache",
      variables() {
        return {
          Org_Code: localStorage.getItem("orgCode"),
          Url_Hash: localStorage.getItem("urlHash"),
        };
      },
      result({ data }) {
        if (data && data.getPersonalInfo) {
          this.personalInfoDetails = data.getPersonalInfo;
          this.fileName = this.personalInfoDetails.Photo_Path;
          if (this.fileName) {
            this.retrieveProfilePicture();
          } else {
            this.profileLoading = false;
          }
          this.$emit("personal-details", this.personalInfoDetails);
          this.step1Details = {
            Salutation: this.personalInfoDetails.Salutation,
            Emp_First_Name: this.personalInfoDetails.Emp_First_Name,
            Emp_Last_Name: this.personalInfoDetails.Emp_Last_Name,
            Emp_Middle_Name: this.personalInfoDetails.Emp_Middle_Name,
            Nick_Name: this.personalInfoDetails.Nick_Name,
            DOB: this.personalInfoDetails.DOB,
            Place_Of_Birth: this.personalInfoDetails.Place_Of_Birth,
            Gender_Id: this.personalInfoDetails.Gender_Id,
            Gender: this.personalInfoDetails.Gender,
            Marital_Status: this.personalInfoDetails.Marital_Status,
            Blood_Group: this.personalInfoDetails.Blood_Group,
            Lang_Known: this.personalInfoDetails.Lang_Known.languages.map(
              (obj) => {
                return obj.Lang_Id;
              }
            ),
            Nationality_Id: this.personalInfoDetails.Nationality_Id,
            Nationality: this.personalInfoDetails.Nationality,
            Personal_Email: this.personalInfoDetails.Personal_Email,
            Ethnic_Race: this.personalInfoDetails.Ethnic_Race,
            hobbies: this.personalInfoDetails.hobbies,
            Religion_Id: this.personalInfoDetails.Religion_Id,
            Religion: this.personalInfoDetails.Religion,
            Appellation: this.personalInfoDetails.Appellation,
            Caste: this.personalInfoDetails.Caste,
            Is_Manager: this.personalInfoDetails.Is_Manager,
            Physically_Challenged:
              this.personalInfoDetails.Physically_Challenged,
            Smoker: this.personalInfoDetails.Smoker,
            Smokerasof: this.personalInfoDetails.Smokerasof,
            PAN: this.personalInfoDetails.PAN,
            Aadhaar_Card_Number: this.personalInfoDetails.Aadhaar_Card_Number,
            Military_Service: this.personalInfoDetails.Military_Service,
            Gender_Orientations: this.personalInfoDetails.Gender_Orientations,
            Pronoun: this.personalInfoDetails.Pronoun,
            PRAN_No: this.personalInfoDetails.PRAN_No,
            Statutory_Insurance_Number:
              this.personalInfoDetails.Statutory_Insurance_Number,
            UAN: this.personalInfoDetails.UAN,
            Tax_Code: this.personalInfoDetails.Tax_Code,
          };
          this.step2Details = {
            Driving_License_No: this.personalInfoDetails.Driving_License_No,
            License_Issue_Date: this.personalInfoDetails.License_Issue_Date,
            License_Expiry_Date: this.personalInfoDetails.License_Expiry_Date,
            Issuing_Authority: this.personalInfoDetails.Issuing_Authority,
            Issuing_Country: this.personalInfoDetails.Issuing_Country,
            Issuing_State: this.personalInfoDetails.Issuing_State,
            Vehicle_Type: this.personalInfoDetails.Vehicle_Type,
            License_File_Name: this.License_File_Name,
            Passport_No: this.personalInfoDetails.Passport_No,
            Passport_File_Name: this.personalInfoDetails.Passport_File_Name,
            Issue_Date: this.personalInfoDetails.Issue_Date,
            Passport_Issuing_Country:
              this.personalInfoDetails.Passport_Issuing_Country,
            Passport_Issuing_Authority:
              this.personalInfoDetails.Passport_Issuing_Authority,
            Expiry_Date: this.personalInfoDetails.Expiry_Date,
          };
          this.step3Details = {
            pApartment_Name: this.personalInfoDetails.pApartment_Name,
            pStreet_Name: this.personalInfoDetails.pStreet_Name,
            pState: this.personalInfoDetails.pState,
            pCountry: this.personalInfoDetails.pCountry,
            pPincode: this.personalInfoDetails.pPincode,
            cApartment_Name: this.personalInfoDetails.cApartment_Name,
            cStreet_Name: this.personalInfoDetails.cStreet_Name,
            cState: this.personalInfoDetails.cState,
            cCountry: this.personalInfoDetails.cCountry,
            cPincode: this.personalInfoDetails.cPincode,
            Use_Location_Address: this.personalInfoDetails.Use_Location_Address,
            Land_Line_No: this.personalInfoDetails.Land_Line_No,
            Mobile_No: this.personalInfoDetails.Mobile_No
              ? this.personalInfoDetails.Mobile_No.replace(/^0| /g, "")
              : this.personalInfoDetails.Mobile_No,
            Mobile_No_Country_Code:
              this.personalInfoDetails.Mobile_No_Country_Code,
            Emergency_Contact_No: this.personalInfoDetails.Fax_No,
            Emergency_Contact_Name:
              this.personalInfoDetails.Emergency_Contact_Name,
            Emergency_Contact_Relation:
              this.personalInfoDetails.Emergency_Contact_Relation,
          };
          let cValid = false,
            pValid = false;
          for (let item of this.cityStateList) {
            if (item.City_Name == this.personalInfoDetails.pCity) {
              this.step3Details.pCity = item.cityStateDetails;
              pValid = true;
            }
            if (item.City_Name == this.personalInfoDetails.cCity) {
              this.step3Details.cCity = item.cityStateDetails;
              cValid = true;
            }
          }
          if (!pValid) {
            this.step3Details.pCity = this.personalInfoDetails.pCity;
          }
          if (!cValid) {
            this.step3Details.cCity = this.personalInfoDetails.cCity;
          }
        }
        this.personalInfoLoading = false;
      },
      // Error handling
      error(getPersonalInfoErr) {
        let snackbarData = {
          isOpen: true,
          message:
            getPersonalInfoErr.graphQLErrors.length !== 0
              ? getPersonalInfoErr.graphQLErrors[0].message
              : "Something went wrong. It could be a possibility due to network connectivity error..",
          type: "warning",
        };
        this.showAlert(snackbarData);
        this.personalInfoLoading = false;
      },
    },
  },

  methods: {
    stepHeading() {
      if (this.selectedInfo === 0) {
        return "Basic Information";
      } else if (this.selectedInfo === 1) {
        return "Address";
      } else {
        return "Dependent Details";
      }
    },

    async onClickNextSection(info) {
      let isFormValid = await this.validateForms();
      if (isFormValid) {
        this.submitPersonalDetails(info.value);
        this.$apollo.queries.getPersonalInfo.refetch();
      }
    },

    async onSaveBasicInfo() {
      let isFormValid = await this.validateForms();
      if (isFormValid) {
        let nextStep = this.selectedInfo + 1;
        this.submitPersonalDetails(nextStep);
      }
    },

    async validateForms() {
      return new Promise((resolve) => {
        switch (this.selectedInfo) {
          case 0:
            this.$refs.basicInfo
              .validateBasicInfo()
              .then((validationResponse) => {
                this.step1Details = this.$refs.basicInfo.basicInfoFields();
                resolve(validationResponse);
              });
            break;
          case 1:
            this.$refs.addressInfo
              .validateAddressInfo()
              .then((validationResponse) => {
                this.step3Details = this.$refs.addressInfo.addressInfoDetails();
                resolve(validationResponse);
              });
            break;
          default:
            resolve(true);
            break;
        }
      });
    },

    onClickPrevBtn() {
      this.personalInfoLoading = true;
      this.$apollo.queries.getPersonalInfo.refetch();
      this.selectedInfo -= 1;
    },

    submitPersonalDetails(nextStep) {
      let vm = this;
      vm.saveLoading = true;
      let submitObj = {
        Org_Code: localStorage.getItem("orgCode"),
        Url_Hash: localStorage.getItem("urlHash"),
        Photo_Path: this.fileName,
        ...vm.step1Details,
        ...vm.step2Details,
        ...vm.step3Details,
      };
      vm.$apollo
        .mutate({
          mutation: UPDATE_PERSONAL_INFO,
          variables: submitObj,
          client: "apolloClientA",
        })
        .then(() => {
          vm.saveLoading = false;
          this.$apollo.queries.getPersonalInfo.refetch();
          vm.selectedInfo = nextStep;
        })
        .catch((updateErr) => {
          vm.saveLoading = false;
          let snackbarData = {
            isOpen: true,
            message:
              updateErr.graphQLErrors.length !== 0
                ? updateErr.graphQLErrors[0].message
                : "Something went wrong. It could be a possibility due to network connectivity error..",
            type: "warning",
          };
          vm.showAlert(snackbarData);
        });
    },

    onSavedDependent() {
      this.$emit("move-to-next-section");
    },

    async retrieveProfilePicture() {
      this.profileLoading = true;
      await this.$store
        .dispatch("retrieveImage", {
          fileName: this.fileName,
          type: "profile",
        })
        .then((resFileName) => {
          this.photoPath = "data:image/png;base64," + resFileName;
          this.profileLoading = false;
        });
    },

    toggleShow() {
      this.show = !this.show;
    },

    updateProfile(fileObj) {
      this.fileName = fileObj.filePath;
      this.updateProfilePath(fileObj.action);
    },

    updateProfilePath(action) {
      this.$apollo
        .mutate({
          mutation: UPDATE_PHOTO_PATH,
          variables: {
            Photo_Path: this.fileName,
            Org_Code: localStorage.getItem("orgCode"),
            Url_Hash: localStorage.getItem("urlHash"),
          },
        })
        .then(() => {
          this.show = false;
          let alertMessage = "";
          if (action === "delete") {
            alertMessage = "Profile picture removed successfully!";
          } else if (action === "update") {
            alertMessage = "Profile picture updated successfully!";
          } else {
            alertMessage = "Profile picture added successfully!";
          }
          // this.photoPath = profilePath;
          let snackbarData = {
            isOpen: true,
            type: "success",
            message: alertMessage,
          };
          this.showAlert(snackbarData);
        })
        .catch((err) => {
          let snackbarData = {
            isOpen: true,
            message:
              err.graphQLErrors && err.graphQLErrors.length !== 0
                ? err.graphQLErrors[0].message
                : "Something went wrong. It could be a possibility due to network connectivity error..",
            type: "warning",
          };
          this.showAlert(snackbarData);
        });
    },

    // show error or success message
    showAlert(snackbarData) {
      this.$store.commit("OPEN_SNACKBAR", snackbarData);
    },
  },
};
</script>
