<template>
  <div>
    <div class="d-flex justify-end mb-3">
      <v-btn color="primary" text @click="addMoreCertificate()">
        <v-icon>add</v-icon>
        Add Certificate
      </v-btn>
    </div>
    <v-form
      v-for="(certificate, index) in addCertificate"
      :key="index"
      :ref="'certificateForm' + index"
    >
      <div
        v-if="addCertificate.length > 1 || certificate.certificateId"
        class="d-flex justify-end mb-3"
      >
        <v-btn
          color="primary lighten-1"
          text
          @click="removeCertificate(index, certificate)"
        >
          <v-icon>delete</v-icon>
          Remove
        </v-btn>
      </div>
      <ValidationObserver :ref="'certificateForm' + index">
        <v-row>
          <v-col cols="12">
            <v-card flat class="pa-6">
              <v-row>
                <v-col cols="12" lg="6" xl="6" md="6" sm="12" xs="12">
                  <ValidationProvider
                    :ref="'certificateNameProvider' + index"
                    v-slot="{ errors }"
                    name="Certificate Name"
                    rules="required|multilingualNameNumericValidation"
                  >
                    <v-text-field
                      :id="'CertificateName'"
                      v-model="certificate.certificateName"
                      outlined
                      :counter="30"
                      :maxlength="30"
                      label="Certificate Name*"
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" lg="6" xl="6" md="6" sm="12" xs="12">
                  <ValidationProvider
                    :ref="'receivedFromProvider' + index"
                    v-slot="{ errors }"
                    name="Received From"
                    rules="multilingualNameNumericValidation|required"
                  >
                    <v-text-field
                      :id="'CertificateReceivedFrom'"
                      v-model="certificate.receivedFrom"
                      outlined
                      :counter="30"
                      :maxlength="30"
                      label="Received From*"
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" lg="6" xl="6" md="6" sm="12" xs="12">
                  <v-dialog v-model="receivedOnModal" width="290">
                    <template #activator="{ on }">
                      <ValidationProvider
                        :ref="'receivedOnProvider' + index"
                        v-slot="{ errors }"
                        name="Received On"
                        rules="required"
                      >
                        <v-text-field
                          :id="'CertificateReceivedOn'"
                          :value="formatDate(certificate.receivedOn)"
                          outlined
                          readonly
                          label="Received On*"
                          v-on="on"
                          :error-messages="errors"
                        ></v-text-field>
                      </ValidationProvider>
                    </template>
                    <v-date-picker
                      v-model="certificate.receivedOn"
                      :min="minJoinDate"
                      :max="maxYear"
                      @change="receivedOnModal = false"
                    >
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" lg="6" xl="6" md="6" sm="12" xs="12">
                  <DragAndDropFiles
                    :id="'certi' + index"
                    file-category="document"
                    :key-index="'certi-file-upload-' + index"
                    :old-file-name="certificate.fileName"
                    @file-event-success="updateFileName($event, index)"
                    @file-event-triggered="isFileLoading = $event"
                    :message="true"
                  ></DragAndDropFiles>
                </v-col>
                <v-col
                  v-if="certificate.fileNameWithoutFormat"
                  cols="12"
                  lg="6"
                  xl="6"
                  md="6"
                  sm="12"
                  xs="12"
                >
                  <ValidationProvider
                    :ref="'cSubTypeProvider' + index"
                    v-slot="{ errors }"
                    name="Document Sub Type"
                    :rules="certificate.fileNameWithoutFormat ? 'required' : ''"
                  >
                    <v-autocomplete
                      :id="'DocumentSubType'"
                      v-model="certificate.certificateSubType"
                      outlined
                      :items="subTypeList"
                      item-text="Document_Sub_Type"
                      item-value="Document_Sub_Type_Id"
                      label="Document Sub Type*"
                      :error-messages="errors"
                    ></v-autocomplete>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </ValidationObserver>
      <v-divider></v-divider>
    </v-form>
    <div class="mt-3 d-flex justify-end">
      <v-btn
        color="primary"
        style="border-radius: 10px"
        large
        :loading="saveLoading"
        :disabled="isFileLoading"
        @click="onSaveCertificate()"
        >Save & Continue</v-btn
      >
      <v-btn
        v-if="showSkip"
        class="ml-2"
        elevation="2"
        text
        color="primary"
        style="border-radius: 10px"
        large
        @click="onSkipCertificate()"
        >Skip</v-btn
      >
    </div>
    <v-overlay
      absolute
      :value="certiLoading || saveLoading"
      color="#fff"
      z-index="1"
    >
      <v-progress-circular
        color="primary"
        indeterminate
        size="50"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import {
  GET_CERTIFICATE,
  ADD_CERTIFICATE,
  UPDATE_CERTIFICATE,
  DELETE_CERTIFICATE,
  LIST_SUB_DOC_TYPE,
} from "@/graphql/OnboardingQueries";
import DragAndDropFiles from "@/components/custom-components/DragAndDropFiles.vue";
import moment from "moment";
import { scrollScreenToTop } from "../../../helper";

export default {
  name: "Certificate",

  props: {
    showSkip: {
      type: Boolean,
      default: false,
    },
  },

  components: { DragAndDropFiles },

  data: () => ({
    addCertificate: [],
    subTypeList: [],
    minJoinDate: "",
    maxYear: "",
    receivedOnModal: false,
    saveLoading: false,
    certiLoading: true,
    apiCallCount: 0,
    isFileLoading: false,
  }),

  apollo: {
    listDocumentSubType: {
      query: LIST_SUB_DOC_TYPE,
      client: "apolloClientA",
      variables() {
        return {
          Org_Code: localStorage.getItem("orgCode"),
          Url_Hash: localStorage.getItem("urlHash"),
          type_id: 4,
        };
      },
      result({ data }) {
        if (data && data.listDocumentSubType) {
          this.subTypeList = data.listDocumentSubType.documentSubType;
        }
      },
    },
    getCertification: {
      query: GET_CERTIFICATE,
      client: "apolloClientA",
      fetchPolicy: "no-cache",
      variables() {
        return {
          Org_Code: localStorage.getItem("orgCode"),
          Url_Hash: localStorage.getItem("urlHash"),
        };
      },
      result({ data }) {
        if (data && data.getCertification) {
          const { certificates } = data.getCertification;
          if (certificates && certificates.length > 0) {
            let certificateList = [];
            for (let certificate of certificates) {
              let certificateOnj = {
                certificateId: certificate.Certification_Id,
                fileName: certificate.File_Name
                  ? certificate.File_Name.split("?")[3]
                  : "",
                fileNameWithoutFormat: certificate.File_Name,
                certificateSubType: certificate.Sub_Type_Id
                  ? certificate.Sub_Type_Id
                  : 0,
                certificateName: certificate.Certification_Name,
                receivedFrom: certificate.Certificate_Received_From,
                receivedOn: certificate.Received_Date,
              };
              certificateList.push(certificateOnj);
            }
            this.addCertificate = certificateList;
            this.$emit("certificate-done");
          } else {
            this.addCertificate.push({
              certificateId: 0,
              fileName: "",
              fileNameWithoutFormat: "",
              certificateSubType: 0,
              certificateName: "",
              receivedFrom: "",
              receivedOn: "",
            });
          }
        }
        this.certiLoading = false;
      },
      error() {
        this.certiLoading = false;
      },
    },
  },

  computed: {
    formatDate() {
      return (date) => {
        return date ? moment(date).format("DD/MM/YYYY") : "";
      };
    },
  },

  mounted() {
    scrollScreenToTop();
  },

  created() {
    let storedDob = localStorage.getItem("DOB");
    if (storedDob && storedDob !== "null") {
      this.minJoinDate = moment(storedDob).format("YYYY-MM-DD");
    }
    this.maxYear = moment().format("YYYY-MM-DD");
  },

  methods: {
    updateFileName(fileName, index) {
      this.addCertificate[index]["fileNameWithoutFormat"] = fileName;
    },
    addMoreCertificate() {
      this.validateCertificate().then((isValid) => {
        if (isValid) {
          this.addCertificate.push({
            certificateId: 0,
            fileName: "",
            fileNameWithoutFormat: "",
            certificateSubType: 0,
            certificateName: "",
            receivedFrom: "",
            receivedOn: "",
          });
        }
      });
    },
    validateCertificate() {
      return new Promise((resolve) => {
        let callCount = 0,
          isValidCount = 0;
        for (let cIndex in this.addCertificate) {
          this.$refs["certificateForm" + cIndex][0]
            .validate()
            .then((validationResponse) => {
              callCount += 1;
              if (validationResponse) {
                isValidCount += 1;
                if (callCount === this.addCertificate.length) {
                  resolve(isValidCount === this.addCertificate.length);
                }
              }
            });
        }
      });
    },

    onSaveCertificate() {
      this.apiCallCount = 0;
      for (let certificate of this.addCertificate) {
        this.validateCertificate().then((isValid) => {
          if (isValid) {
            this.apiCallCount += 1;
            if (certificate.certificateId) {
              this.updateCertificateDetails(certificate);
            } else {
              this.addCertificateDetails(certificate);
            }
          }
        });
      }
    },

    onSkipCertificate() {
      this.$emit("certificate-saved");
    },

    addCertificateDetails(certificateItem) {
      let vm = this;
      vm.saveLoading = true;
      vm.$apollo
        .mutate({
          mutation: ADD_CERTIFICATE,
          variables: {
            Org_Code: localStorage.getItem("orgCode"),
            Url_Hash: localStorage.getItem("urlHash"),
            Certification_Name: certificateItem.certificateName,
            Received_Date: certificateItem.receivedOn,
            Certificate_Received_From: certificateItem.receivedFrom,
            File_Name: certificateItem.fileNameWithoutFormat,
            Sub_Type_Id: certificateItem.certificateSubType,
          },
          client: "apolloClientA",
        })
        .then(() => {
          if (vm.apiCallCount === vm.addCertificate.length) {
            vm.$emit("certificate-saved");
          }
        })
        .catch((addErr) => {
          let snackbarData = {
            isOpen: true,
            message:
              addErr.graphQLErrors.length !== 0
                ? addErr.graphQLErrors[0].message
                : "Something went wrong. It could be a possibility due to network connectivity error..",
            type: "warning",
          };
          vm.showAlert(snackbarData);
          if (vm.apiCallCount === vm.addCertificate.length) {
            vm.saveLoading = false;
          }
        });
    },

    updateCertificateDetails(certificateItem) {
      let vm = this;
      vm.saveLoading = true;
      vm.$apollo
        .mutate({
          mutation: UPDATE_CERTIFICATE,
          variables: {
            Org_Code: localStorage.getItem("orgCode"),
            Url_Hash: localStorage.getItem("urlHash"),
            Certification_Id: certificateItem.certificateId,
            Certification_Name: certificateItem.certificateName,
            Received_Date: certificateItem.receivedOn,
            Certificate_Received_From: certificateItem.receivedFrom,
            File_Name: certificateItem.fileNameWithoutFormat,
            Sub_Type_Id: certificateItem.certificateSubType,
          },
          client: "apolloClientA",
        })
        .then(() => {
          if (vm.apiCallCount === vm.addCertificate.length) {
            vm.$emit("certificate-saved");
          }
        })
        .catch((addErr) => {
          let snackbarData = {
            isOpen: true,
            message:
              addErr.graphQLErrors.length !== 0
                ? addErr.graphQLErrors[0].message
                : "Something went wrong. It could be a possibility due to network connectivity error..",
            type: "warning",
          };
          vm.showAlert(snackbarData);
          if (vm.apiCallCount === vm.addCertificate.length) {
            vm.saveLoading = false;
          }
        });
    },

    removeCertificate(index, certificateItem) {
      if (certificateItem.certificateId) {
        let vm = this;
        this.$apollo
          .mutate({
            mutation: DELETE_CERTIFICATE,
            variables: {
              Org_Code: localStorage.getItem("orgCode"),
              Url_Hash: localStorage.getItem("urlHash"),
              Certification_Id: certificateItem.certificateId,
            },
            client: "apolloClientA",
          })
          .then(() => {
            let snackbarData = {
              isOpen: true,
              message: "Deleted Successfully",
              type: "success",
            };
            vm.showAlert(snackbarData);
            vm.addCertificate.splice(index, 1);
            if (index === 0 && vm.addCertificate.length === 0) {
              vm.addCertificate.push({
                certificateId: 0,
                fileName: "",
                fileNameWithoutFormat: "",
                certificateSubType: 0,
                certificateName: "",
                receivedFrom: "",
                receivedOn: "",
              });
            }
          })
          .catch((deleteErr) => {
            let snackbarData = {
              isOpen: true,
              message:
                deleteErr.graphQLErrors.length !== 0
                  ? deleteErr.graphQLErrors[0].message
                  : "Something went wrong. It could be a possibility due to network connectivity error..",
              type: "warning",
            };
            vm.showAlert(snackbarData);
          });
      } else {
        this.addCertificate.splice(index, 1);
      }
    },
    // show error or success message
    showAlert(snackbarData) {
      this.$store.commit("OPEN_SNACKBAR", snackbarData);
    },
  },
};
</script>
