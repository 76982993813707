<template>
  <div>
    <div class="full-height">
      <v-row justify="center" style="height: 90%">
        <v-col cols="11">
          <v-card class="pa-6 my-4" width="100%" raised height="100%">
            <div class="d-flex align-center">
              <v-icon color="primary" @click="onClickPrevBtn()"
                >arrow_back</v-icon
              >
              <div class="pl-2 text-h5 primary--text font-weight-medium">
                Documents Information
              </div>
            </div>
            <div class="d-flex justify-end">
              <v-btn color="primary" text @click="addMoreDocuments()">
                <v-icon>add</v-icon>Add Documents
              </v-btn>
            </div>
            <v-form
              v-for="(document, index) in addDocuments"
              :key="index"
              :ref="'documentForm' + index"
            >
              <div
                v-if="
                  (addDocuments.length > 1 || document.Document_Id) &&
                  document.Mandatory == 'No'
                "
                class="d-flex justify-end mb-3"
              >
                <v-btn
                  color="primary lighten-1"
                  text
                  @click="removeDocuments(index, document)"
                >
                  <v-icon>delete</v-icon>
                  Remove
                </v-btn>
              </div>
              <ValidationObserver :ref="'documentsForm' + index">
                <v-row>
                  <v-col cols="12">
                    <v-card flat class="pa-6">
                      <v-row>
                        <!-- <v-col cols="12" lg="4" xl="4" md="6" sm="12" xs="12">
                          <ValidationProvider
                            ref="documentNameProvider"
                            v-slot="{ errors }"
                            name="Document Name"
                            rules="required|alphaNumSpaceWithTwoSymbol"
                          >
                            <v-text-field
                              :id="'documentName'"
                              v-model="document.Document_Name"
                              outlined
                              :counter="50"
                              :maxlength="50"
                              label="Document Name"
                              :error-messages="errors"
                            ></v-text-field>
                          </ValidationProvider>
                        </v-col> -->
                        <v-col cols="12" lg="4" xl="4" md="6" sm="12" xs="12">
                          <ValidationProvider
                            ref="documentCategory"
                            v-slot="{ errors }"
                            name="Document Category"
                            rules="required"
                          >
                            <v-text-field
                              v-if="document.Mandatory == 'Yes'"
                              :id="'DocumentCategory-1'"
                              v-model="document.Category_Fields"
                              outlined
                              disabled
                              :error-messages="errors"
                              label="Document Category"
                            ></v-text-field>
                            <v-autocomplete
                              v-if="document.Mandatory == 'No'"
                              :id="'DocumentCategory-2'"
                              v-model="document.Category_Id"
                              :items="documentCategoryList"
                              item-text="Category_Name"
                              item-value="Category_Id"
                              outlined
                              :error-messages="errors"
                              label="Document Category"
                              @change="
                                getDocumentTypes(document.Category_Id, index)
                              "
                            ></v-autocomplete>
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" lg="4" xl="4" md="6" sm="12" xs="12">
                          <ValidationProvider
                            ref="documentType"
                            v-slot="{ errors }"
                            name="Document Type"
                            rules="required"
                          >
                            <v-text-field
                              v-if="document.Mandatory == 'Yes'"
                              :id="'DocumentType-1'"
                              v-model="document.Document_Type"
                              outlined
                              disabled
                              :error-messages="errors"
                              label="Document Type"
                            ></v-text-field>
                            <v-autocomplete
                              v-if="document.Mandatory == 'No'"
                              :id="'DocumentType-2'"
                              v-model="document.Document_Type_Id"
                              :items="documentTypeList[index]"
                              item-text="Document_Type"
                              item-value="Document_Type_Id"
                              outlined
                              :error-messages="errors"
                              label="Document Type"
                              @change="
                                getSubDocumentTypeList(
                                  document.Document_Type_Id,
                                  index
                                )
                              "
                            ></v-autocomplete>
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" lg="4" xl="4" md="6" sm="12" xs="12">
                          <ValidationProvider
                            ref="subDocumentType"
                            v-slot="{ errors }"
                            name="Sub Document Type"
                            rules="required"
                          >
                            <v-text-field
                              v-if="document.Mandatory == 'Yes'"
                              :id="'SubDocumentType'"
                              v-model="document.Document_Sub_Type"
                              outlined
                              disabled
                              :error-messages="errors"
                              label="Document Sub Type"
                            ></v-text-field>
                            <v-autocomplete
                              v-if="document.Mandatory == 'No'"
                              :id="'SubDocumentType'"
                              v-model="document.Document_Sub_Type_Id"
                              :items="subDocumentTypeList[index]"
                              item-text="Document_Sub_Type"
                              item-value="Document_Sub_Type_Id"
                              outlined
                              :error-messages="errors"
                              label="Document Sub Type"
                              @change="setInstructionObject($event, index)"
                            ></v-autocomplete>
                          </ValidationProvider>
                          <div
                            v-if="
                              instruction[index] &&
                              instruction[index].Instruction &&
                              instruction[index].FileName
                            "
                            class="text-caption ml-2"
                            style="line-height: 1; font-weight: bold"
                          >
                            <span
                              v-html="
                                instructionFirstPart(
                                  instruction[index].Instruction
                                )
                              "
                            >
                            </span>
                            <span
                              style="
                                color: blue;
                                text-decoration: underline;
                                cursor: pointer;
                              "
                              @click="
                                retrieveFileAndRedirect(
                                  instruction[index].FileName
                                )
                              "
                              >here</span
                            >
                            <span
                              :style="
                                customStyles(instruction[index].Instruction)
                              "
                              v-html="
                                instructionSecondPart(
                                  instruction[index].Instruction
                                )
                              "
                            ></span>
                          </div>
                        </v-col>
                        <v-col cols="12" lg="4" xl="4" md="6" sm="12" xs="12">
                          <DragAndDropFiles
                            :id="'document' + index"
                            file-category="document"
                            :key-index="'document-file-upload-' + index"
                            :old-file-name="document.File_Name"
                            :is-error="document.fileValidationError"
                            :Mandatory="true"
                            :message="true"
                            @file-event-success="updateFileName($event, index)"
                            @file-event-triggered="isFileLoading = $event"
                          ></DragAndDropFiles>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </ValidationObserver>
              <v-divider></v-divider>
            </v-form>
            <div class="mt-3 d-flex justify-end">
              <v-btn
                color="primary"
                style="border-radius: 10px"
                large
                :loading="saveLoading"
                :disabled="isFileLoading"
                @click="onSaveDocuments()"
                >Save & Continue</v-btn
              >
              <v-btn
                v-if="showSkip"
                class="ml-2"
                elevation="2"
                text
                color="primary"
                style="border-radius: 10px"
                large
                @click="onSkipDocuments()"
                >Skip</v-btn
              >
            </div>
            <v-overlay
              absolute
              :value="documentLoading || saveLoading"
              color="#fff"
              z-index="1"
            >
              <v-progress-circular
                color="primary"
                indeterminate
                size="50"
              ></v-progress-circular>
            </v-overlay>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import {
  GET_ALL_DOCUMENTS,
  ADD_DOCUMENT,
  UPDATE_DOC_DOCUMENT,
  DELETE_DOC_DOCUMENT,
  LIST_DOC_CATEGORY,
  LIST_DOC_TYPE,
  LIST_SUB_DOC_TYPE,
  LIST_CANDIDATE_DOCUMENT_DETAILS,
} from "@/graphql/OnboardingQueries";
import DragAndDropFiles from "@/components/custom-components/DragAndDropFiles.vue";
import config from "@/config.js";
import { scrollScreenToTop } from "../../../helper";

export default {
  name: "SkillProfiles",

  components: { DragAndDropFiles },

  data: () => ({
    addDocuments: [],
    documentCategoryList: [],
    documentTypeList: [],
    subDocumentTypeList: [],
    MandatoryDocuments: [],
    saveLoading: false,
    apiCallCount: 0,
    documentLoading: true,
    isFileLoading: false,
    showSkip: true,
    instruction: [],
  }),

  apollo: {
    listDocumentCategory: {
      query: LIST_DOC_CATEGORY,
      variables() {
        return {
          Org_Code: localStorage.getItem("orgCode"),
          Url_Hash: localStorage.getItem("urlHash"),
        };
      },
      loading: true,
      result({ data }) {
        if (data && data.listDocumentCategory) {
          this.documentCategoryList = data.listDocumentCategory.category;
        }
      },
    },
  },

  computed: {
    domainName() {
      return config.domain;
    },
    instructionFirstPart() {
      return (instruction) => {
        let str = instruction.replace(/^<p>|<\/p>$/g, "");
        return str.split("{{")[0] ? str.split("{{")[0] : "";
      };
    },
    instructionSecondPart() {
      return (instruction) => {
        let str = instruction.replace(/^<p>|<\/p>$/g, "");
        return str.split("}}")[1] ? str.split("}}")[1] : "";
      };
    },
    customStyles() {
      return (instruction) => {
        const styleRegex = /style="([^"]*)"/;
        const match = instruction.match(styleRegex);

        if (match) {
          const styles = match[1]; // Extracted styles
          return styles;
        } else {
          return "";
        }
      };
    },
  },
  mounted() {
    scrollScreenToTop();
    this.listMandatoryDocuments();
  },

  methods: {
    getAllDocuments() {
      let vm = this;
      this.isFileLoading = true;
      this.documentLoading = true;
      vm.$apollo
        .query({
          query: GET_ALL_DOCUMENTS,
          client: "apolloClientA",
          fetchPolicy: "no-cache",
          variables: {
            Org_Code: localStorage.getItem("orgCode"),
            Url_Hash: localStorage.getItem("urlHash"),
          },
        })
        .then(async (data) => {
          data = data.data;
          if (data && data.getAllDocuments) {
            const { documents } = data.getAllDocuments;
            if (documents && documents.length > 0) {
              let documentList = [];
              this.documentTypeList = [];
              this.subDocumentTypeList = [];
              for (let [index, document] of documents.entries()) {
                await this.getDocumentTypes(document.Category_Id, index);
                await this.getSubDocumentTypeList(
                  document.Document_Type_Id,
                  index
                );
                let storedFileName = "",
                  storedFileNameList = this.MandatoryDocuments.filter(
                    (el) => el.Document_Type_Id == document.Document_Type_Id
                  );
                if (storedFileNameList && storedFileNameList.length > 0) {
                  storedFileName = storedFileNameList[0].File_Name;
                }
                let documentObj = {
                  Document_Id: document.Document_Id,
                  Document_Name: document.Document_Name,
                  Category_Id: document.Category_Id,
                  Category_Fields: document.Category_Name,
                  Document_Type_Id: document.Document_Type_Id,
                  Document_Type: document.Document_Type,
                  Document_Sub_Type_Id: document.Document_Sub_Type_Id,
                  Document_Sub_Type: document.Document_Sub_Type,
                  Stored_File_Name: storedFileName,
                  File_Name: document.File_Name
                    ? document.File_Name.split("?")[3]
                    : "",
                  Instruction: document.Instruction,
                  fileNameWithoutFormat: document.File_Name,
                  fileValidationError: "",
                  Mandatory: document.Mandatory,
                };
                documentList.push(documentObj);
                this.instruction.push({
                  Instruction: documentObj.Instruction,
                  FileName: documentObj.Stored_File_Name,
                });
              }
              this.addDocuments = documentList;
            }
            if (this.MandatoryDocuments.length > 0) {
              let documentSubIdList = [];
              for (let i = 0; i < this.addDocuments.length; i++) {
                documentSubIdList.push(
                  this.addDocuments[i].Document_Sub_Type_Id
                );
              }
              for (let item of this.MandatoryDocuments) {
                if (!documentSubIdList.includes(item.Document_Sub_Type_Id)) {
                  this.addDocuments.push({
                    Document_Name: item.Document_Name,
                    Category_Fields: item.Category_Fields,
                    Category_Id: item.Category_Id,
                    Document_Type: item.Document_Type,
                    Document_Type_Id: item.Document_Type_Id,
                    Document_Sub_Type: item.Document_Sub_Type,
                    Document_Sub_Type_Id: item.Document_Sub_Type_Id,
                    Stored_File_Name: item.File_Name,
                    File_Name: "",
                    fileNameWithoutFormat: "",
                    fileValidationError: "",
                    Mandatory: "Yes",
                    Instruction: item.Instruction,
                  });
                  this.instruction.push({
                    Instruction: item.Instruction,
                    FileName: item.File_Name,
                  });
                  this.showSkip = false;
                }
              }
            }
            if (this.MandatoryDocuments.length <= 0 && documents.length <= 0) {
              this.addDocuments.push({
                Document_Name: "",
                Document_Category: null,
                Document_Type: null,
                Document_Sub_Type: null,
                Stored_File_Name: "",
                File_Name: "",
                fileNameWithoutFormat: "",
                fileValidationError: "",
                Mandatory: "No",
                Instruction: "",
              });
            }
            this.documentLoading = false;
            this.saveLoading = false;
          } else {
            this.addDocuments.push({
              Document_Name: "",
              Document_Category: null,
              Document_Type: null,
              Document_Sub_Type: null,
              Stored_File_Name: "",
              File_Name: "",
              fileNameWithoutFormat: "",
              fileValidationError: "",
              Mandatory: "No",
              Instruction: "",
            });
          }
        })
        .catch(() => {
          this.documentLoading = false;
          this.saveLoading = false;
          this.addDocuments.push({
            Document_Name: "",
            Document_Category: null,
            Document_Type: null,
            Document_Sub_Type: null,
            Stored_File_Name: "",
            File_Name: "",
            fileNameWithoutFormat: "",
            fileValidationError: "",
            Mandatory: "No",
            Instruction: "",
          });
        })
        .catch((err) => {
          let snackbarData = {
            isOpen: true,
            message:
              err.graphQLErrors.length !== 0
                ? err.graphQLErrors[0].message
                : "Something went wrong. It could be a possibility due to network connectivity error..",
            type: "warning",
          };
          this.showAlert(snackbarData);
          this.documentLoading = false;
          this.saveLoading = false;
        });
    },
    listMandatoryDocuments() {
      let vm = this;
      vm.$apollo
        .query({
          query: LIST_CANDIDATE_DOCUMENT_DETAILS,
          variables: {
            urlHash: localStorage.getItem("urlHash"),
          },
          client: "apolloClientB",
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          data = data.data;
          if (
            data &&
            data.listCandidateDocumentDetails &&
            data.listCandidateDocumentDetails.documentDetails &&
            data.listCandidateDocumentDetails.documentDetails.length > 0
          ) {
            let filteredList =
              data.listCandidateDocumentDetails.documentDetails.filter(
                (ele) => ele.Mandatory === "Yes"
              );
            this.MandatoryDocuments = [...filteredList];
          }
          this.getAllDocuments();
        });
    },
    getDocumentTypes(categoryId, index) {
      this.documentTypeList[index] = [];
      this.subDocumentTypeList[index] = [];
      this.instruction.splice(index, 1);
      let vm = this;
      vm.$apollo
        .query({
          query: LIST_DOC_TYPE,
          variables: {
            Category_Id: categoryId
              ? categoryId
              : this.documentCategoryList[index],
            Org_Code: localStorage.getItem("orgCode"),
            Url_Hash: localStorage.getItem("urlHash"),
          },
        })
        .then((res) => {
          const documentType = res?.data?.listDocumentType?.documentType ?? [];
          this.documentTypeList.splice(index, 1, documentType);
        })
        .catch((err) => {
          let snackbarData = {
            isOpen: true,
            message:
              err.graphQLErrors.length !== 0
                ? err.graphQLErrors[0].message
                : "Something went wrong. It could be a possibility due to network connectivity error..",
            type: "warning",
          };
          vm.showAlert(snackbarData);
        });
    },

    getSubDocumentTypeList(documentType, index) {
      this.subDocumentTypeList[index] = [];
      this.instruction.splice(index, 1);
      let vm = this;
      vm.$apollo
        .query({
          query: LIST_SUB_DOC_TYPE,
          variables: {
            type_id: documentType
              ? documentType
              : this.subDocumentTypeList[index],
            Org_Code: localStorage.getItem("orgCode"),
            Url_Hash: localStorage.getItem("urlHash"),
          },
        })
        .then((res) => {
          const subDocumentType =
            res?.data?.listDocumentSubType?.documentSubType ?? [];
          this.subDocumentTypeList.splice(index, 1, subDocumentType);
        })
        .catch((err) => {
          let snackbarData = {
            isOpen: true,
            message:
              err.graphQLErrors.length !== 0
                ? err.graphQLErrors[0].message
                : "Something went wrong. It could be a possibility due to network connectivity error..",
            type: "warning",
          };
          vm.showAlert(snackbarData);
        });
    },

    updateFileName(fileName, index) {
      this.addDocuments[index]["fileNameWithoutFormat"] = fileName;
      this.addDocuments[index]["fileValidationError"] = "";
    },

    onClickPrevBtn() {
      this.$emit("back-to-prev");
    },

    addMoreDocuments() {
      this.validateDocuments().then((isValid) => {
        if (isValid) {
          this.addDocuments.push({
            Document_Name: "",
            Document_Category: null,
            Document_Type: null,
            Document_Sub_Type: null,
            Stored_File_Name: "",
            File_Name: "",
            fileNameWithoutFormat: "",
            fileValidationError: "",
            Mandatory: "No",
            Instruction: "",
          });
        }
      });
    },
    validateDocuments() {
      return new Promise((resolve) => {
        let callCount = 0,
          isValidCount = 0;
        for (let aIndex in this.addDocuments) {
          this.$refs["documentsForm" + aIndex][0]
            .validate()
            .then((validationResponse) => {
              callCount += 1;
              let aIndexFileName =
                this.addDocuments[aIndex]["fileNameWithoutFormat"];
              if (validationResponse && aIndexFileName) {
                isValidCount += 1;
                if (callCount === this.addDocuments.length) {
                  resolve(isValidCount === this.addDocuments.length);
                }
              } else if (!aIndexFileName) {
                this.addDocuments[aIndex]["fileValidationError"] =
                  "Document is required";
                let form = this.$refs["documentForm" + aIndex][0];
                form.$el.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                });
                resolve(false);
              }
            });
        }
      });
    },

    onSaveDocuments() {
      this.apiCallCount = 0;
      for (let document of this.addDocuments) {
        this.validateDocuments().then((isValid) => {
          if (isValid) {
            this.apiCallCount += 1;
            if (document.Document_Id) {
              this.updateDocumentDetails(document, this.apiCallCount - 1);
            } else {
              this.addDocumentDetails(document, this.apiCallCount - 1);
            }
          }
        });
      }
    },

    onSkipDocuments() {
      this.$emit("documents-saved");
    },

    addDocumentDetails(documentItem, index) {
      let vm = this;
      vm.saveLoading = true;

      let subDocumentName;
      if (documentItem.Document_Sub_Type == undefined) {
        subDocumentName = this.subDocumentTypeList[index].filter((e) => {
          return e.Document_Sub_Type_Id == documentItem.Document_Sub_Type_Id;
        });
      }
      vm.$apollo
        .mutate({
          mutation: ADD_DOCUMENT,
          variables: {
            Org_Code: localStorage.getItem("orgCode"),
            Url_Hash: localStorage.getItem("urlHash"),
            File_Name: documentItem.fileNameWithoutFormat,
            Document_Name: subDocumentName
              ? subDocumentName[0].Document_Sub_Type
              : documentItem.Document_Sub_Type,
            Category_Id: documentItem.Category_Id,
            Document_Type_Id: documentItem.Document_Type_Id,
            Sub_Document_Type_Id: documentItem.Document_Sub_Type_Id,
          },
          client: "apolloClientA",
        })
        .then(() => {
          if (vm.apiCallCount === vm.addDocuments.length) {
            vm.$emit("documents-saved");
          }
        })
        .catch((addErr) => {
          let snackbarData = {
            isOpen: true,
            message:
              addErr.graphQLErrors.length !== 0
                ? addErr.graphQLErrors[0].message
                : "Something went wrong. It could be a possibility due to network connectivity error..",
            type: "warning",
          };
          vm.showAlert(snackbarData);
          if (vm.apiCallCount === vm.addDocuments.length) {
            vm.saveLoading = false;
          }
        });
    },

    updateDocumentDetails(documentItem, index) {
      let vm = this;
      vm.saveLoading = true;
      let subDocumentName;
      if (documentItem.Document_Sub_Type == undefined) {
        subDocumentName = this.subDocumentTypeList[index].filter((e) => {
          return e.Document_Sub_Type_Id == documentItem.Document_Sub_Type_Id;
        });
      }
      vm.$apollo
        .mutate({
          mutation: UPDATE_DOC_DOCUMENT,
          variables: {
            Org_Code: localStorage.getItem("orgCode"),
            Url_Hash: localStorage.getItem("urlHash"),
            File_Name: documentItem.fileNameWithoutFormat,
            Document_Id: documentItem.Document_Id,
            Document_Name: subDocumentName
              ? subDocumentName[0].Document_Sub_Type
              : documentItem.Document_Sub_Type,
            Category_Id: documentItem.Category_Id,
            Document_Type_Id: documentItem.Document_Type_Id,
            Sub_Document_Type_Id: documentItem.Document_Sub_Type_Id,
          },
          client: "apolloClientA",
        })
        .then(() => {
          if (vm.apiCallCount === vm.addDocuments.length) {
            vm.$emit("documents-saved");
          }
        })
        .catch((addErr) => {
          let snackbarData = {
            isOpen: true,
            message:
              addErr.graphQLErrors.length !== 0
                ? addErr.graphQLErrors[0].message
                : "Something went wrong. It could be a possibility due to network connectivity error..",
            type: "warning",
          };
          vm.showAlert(snackbarData);
          if (vm.apiCallCount === vm.addDocuments.length) {
            vm.saveLoading = false;
          }
        });
    },

    removeDocuments(index, documentItem) {
      if (documentItem.Document_Id) {
        let vm = this;
        this.$apollo
          .mutate({
            mutation: DELETE_DOC_DOCUMENT,
            variables: {
              Document_Id: documentItem.Document_Id,
              Org_Code: localStorage.getItem("orgCode"),
              Url_Hash: localStorage.getItem("urlHash"),
            },
            client: "apolloClientA",
          })
          .then(() => {
            let snackbarData = {
              isOpen: true,
              message: "Deleted Successfully",
              type: "success",
            };
            vm.showAlert(snackbarData);
            vm.addDocuments.splice(index, 1);
            vm.documentTypeList.splice(index, 1);
            vm.subDocumentTypeList.splice(index, 1);
            if (index === 0 && vm.addDocuments.length === 0) {
              vm.addDocuments.push({
                Document_Name: "",
                Document_Category: 0,
                Document_Type: 0,
                Document_Sub_Type: 0,
                Stored_File_Name: "",
                File_Name: "",
                fileNameWithoutFormat: "",
                fileValidationError: "",
                Mandatory: "No",
              });
            }
          })
          .catch((deleteErr) => {
            let snackbarData = {
              isOpen: true,
              message:
                deleteErr.graphQLErrors.length !== 0
                  ? deleteErr.graphQLErrors[0].message
                  : "Something went wrong. It could be a possibility due to network connectivity error..",
              type: "warning",
            };
            vm.showAlert(snackbarData);
          });
      } else {
        this.addDocuments.splice(index, 1);
        this.instruction.splice(index, 1);
        this.documentTypeList.splice(index, 1);
        this.subDocumentTypeList.splice(index, 1);
      }
    },

    async retrieveFileAndRedirect(fileName) {
      this.documentLoading = true;
      await this.$store
        .dispatch("s3FileUploadRetrieveAction", {
          fileName:
            this.domainName +
            "/" +
            localStorage.getItem("orgCode") +
            "/" +
            "Employee Document Download" +
            "/" +
            fileName,
          action: "download",
          type: "documents",
        })
        .then((resFileName) => {
          window.open(resFileName, "_blank");
          this.documentLoading = false;
        })
        .catch((err) => {
          this.documentLoading = false;
          let snackbarData = {
            isOpen: true,
            message:
              err.graphQLErrors && err.graphQLErrors.length !== 0
                ? err.graphQLErrors[0].message
                : "Something went wrong. It could be a possibility due to network connectivity error..",
            type: "warning",
          };
          this.showAlert(snackbarData);
        });
    },

    // show error or success message
    showAlert(snackbarData) {
      this.$store.commit("OPEN_SNACKBAR", snackbarData);
    },

    setInstructionObject(documentId, index) {
      let subTypelist = this.subDocumentTypeList[index];
      let subType = subTypelist.filter(
        (item) => item.Document_Sub_Type_Id == documentId
      );
      this.instruction.splice(index, 1, {
        Instruction: subType[0].Instruction,
        FileName: subType[0].File_Name,
      });
    },
  },
};
</script>
