<template>
  <div>
    <div class="full-height">
      <v-row justify="center" style="height: 90%">
        <v-col cols="11">
          <v-card
            class="pa-6 my-4"
            width="100%"
            min-height="400"
            raised
            height="100%"
          >
            <div class="d-flex align-center">
              <v-icon color="primary" @click="onClickPrevBtn()"
                >arrow_back</v-icon
              >
              <div class="pl-2 text-h5 primary--text font-weight-medium">
                Work Experience
              </div>
              <v-spacer></v-spacer>
              <v-switch
                v-if="!expLoading"
                inset
                color="primary"
                label="No Work Experience"
                @change="onSkipExperience()"
              ></v-switch>
            </div>
            <div v-if="!expLoading" class="d-flex justify-end">
              <v-btn color="primary" text @click="addMoreExperience()">
                <v-icon>add</v-icon>
                Add Experience
              </v-btn>
            </div>
            <v-form
              v-for="(exp, index) in addExperience"
              :key="index"
              :ref="'expForm' + index"
            >
              <div
                v-if="addExperience.length > 1 || exp.experienceId"
                class="d-flex justify-end mb-3"
              >
                <v-btn
                  color="primary lighten-1"
                  text
                  @click="removeExperience(index, exp)"
                >
                  <v-icon>delete</v-icon>
                  Remove
                </v-btn>
              </div>
              <ValidationObserver :ref="'experienceForm' + index">
                <v-row>
                  <v-col cols="12">
                    <v-card flat class="pa-6">
                      <v-row>
                        <v-col cols="12" lg="4" xl="4" sm="6" md="4" xs="12">
                          <ValidationProvider
                            :ref="'companyNameProvider' + index"
                            v-slot="{ errors }"
                            name="Company Name"
                            rules="required|multilingualNameNumericValidation"
                          >
                            <v-text-field
                              :id="'CompanyName'"
                              v-model="exp.companyName"
                              outlined
                              :counter="50"
                              :error-messages="errors"
                              label="Company Name*"
                            >
                              <template v-slot:label>
                                Company Name
                                <span style="color: red">*</span>
                              </template>
                            </v-text-field>
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" lg="4" xl="4" sm="6" md="4" xs="12">
                          <ValidationProvider
                            :ref="'locationProvider' + index"
                            v-slot="{ errors }"
                            name="Location"
                            rules="required|multilingualNameNumericValidation"
                          >
                            <v-text-field
                              :id="'Location'"
                              v-model="exp.location"
                              :maxlength="50"
                              outlined
                              :counter="50"
                              label="Location*"
                              :error-messages="errors"
                            >
                              <template v-slot:label>
                                Location
                                <span style="color: red">*</span>
                              </template>
                            </v-text-field>
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" lg="4" xl="4" sm="6" md="4" xs="12">
                          <ValidationProvider
                            :ref="'designationProvider' + index"
                            v-slot="{ errors }"
                            name="Designation"
                            rules="required|alphaNumSpaceWithTwoSymbol|min:3"
                          >
                            <v-text-field
                              :id="'Designation'"
                              v-model="exp.designation"
                              outlined
                              :counter="50"
                              label="Designation*"
                              :error-messages="errors"
                            >
                              <template v-slot:label>
                                Designation
                                <span style="color: red">*</span>
                              </template>
                            </v-text-field>
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" lg="4" xl="4" sm="6" md="4" xs="12">
                          <v-dialog v-model="fromModal" width="290">
                            <template #activator="{ on }">
                              <ValidationProvider
                                :ref="'fromProvider' + index"
                                v-slot="{ errors }"
                                name="From"
                                rules="required"
                              >
                                <v-text-field
                                  :id="'ExperienceFrom'"
                                  :value="formatDate(exp.from)"
                                  outlined
                                  readonly
                                  label="From*"
                                  v-on="on"
                                  :error-messages="errors"
                                >
                                  <template v-slot:label>
                                    From
                                    <span style="color: red">*</span>
                                  </template>
                                </v-text-field>
                              </ValidationProvider>
                            </template>
                            <v-date-picker
                              v-model="exp.from"
                              :min="minJoinDate"
                              :max="maxYear"
                              @change="emptyTO(index)"
                            ></v-date-picker>
                          </v-dialog>
                        </v-col>
                        <v-col cols="12" lg="4" xl="4" sm="6" md="4" xs="12">
                          <v-dialog v-model="toModal" width="290">
                            <template #activator="{ on }">
                              <ValidationProvider
                                :ref="'toProvider' + index"
                                v-slot="{ errors }"
                                name="To"
                                rules="required"
                              >
                                <v-text-field
                                  :id="'ExperienceTo'"
                                  :value="formatDate(exp.to)"
                                  readonly
                                  outlined
                                  :disabled="!exp.from"
                                  label="To*"
                                  v-on="on"
                                  :error-messages="errors"
                                >
                                  <template v-slot:label>
                                    To
                                    <span style="color: red">*</span>
                                  </template>
                                </v-text-field>
                              </ValidationProvider>
                            </template>
                            <v-date-picker
                              v-model="exp.to"
                              :min="exp.from"
                              :max="maxYear"
                              @change="toModal = false"
                            ></v-date-picker>
                          </v-dialog>
                        </v-col>
                        <v-col cols="12" lg="4" xl="4" sm="12" md="6" xs="12">
                          <DragAndDropFiles
                            :id="'exp' + index"
                            file-category="experience"
                            :old-file-name="exp.fileName"
                            :Mandatory="true"
                            :message="true"
                            :is-error="exp.fileValidationError"
                            :key-index="'exp-file-upload-' + index"
                            @file-event-success="updateFileName($event, index)"
                            @file-event-triggered="isFileLoading = $event"
                          ></DragAndDropFiles>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </ValidationObserver>
              <v-divider></v-divider>
            </v-form>
            <div v-if="!expLoading" class="mt-3 d-flex justify-end">
              <v-btn
                color="primary"
                style="border-radius: 10px"
                large
                :loading="saveLoading"
                :disabled="isFileLoading"
                @click="onSaveExperience()"
                >Save & Continue</v-btn
              >
              <v-btn
                v-if="showSkip"
                class="ml-2"
                elevation="2"
                text
                color="primary"
                style="border-radius: 10px"
                large
                @click="onSkipExperience()"
                >Skip</v-btn
              >
            </div>
            <v-overlay
              absolute
              :value="expLoading || saveLoading"
              color="#fff"
              z-index="1"
            >
              <v-progress-circular
                color="primary"
                indeterminate
                size="50"
              ></v-progress-circular>
            </v-overlay>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import {
  GET_EXPERIENCE,
  ADD_EXPERIENCE,
  UPDATE_EXPERIENCE,
  DELETE_EXPERIENCE,
} from "@/graphql/OnboardingQueries";
import DragAndDropFiles from "@/components/custom-components/DragAndDropFiles.vue";
import moment from "moment";
import { scrollScreenToTop } from "../../../helper";

export default {
  name: "SkillProfiles",

  components: { DragAndDropFiles },

  data: () => ({
    addExperience: [],
    fromModal: false,
    toModal: false,
    maxYear: "",
    minJoinDate: "",
    saveLoading: false,
    expLoading: true,
    apiCallCount: 0,
    showSkip: false,
    isFileLoading: false,
  }),

  created() {
    let storedDob = localStorage.getItem("DOB");
    if (storedDob && storedDob !== "null") {
      this.minJoinDate = moment(storedDob).format("YYYY-MM-DD");
    }
    this.maxYear = moment().format("YYYY-MM-DD");
  },

  apollo: {
    getExperience: {
      query: GET_EXPERIENCE,
      client: "apolloClientA",
      fetchPolicy: "no-cache",
      variables() {
        return {
          Org_Code: localStorage.getItem("orgCode"),
          Url_Hash: localStorage.getItem("urlHash"),
        };
      },
      result({ data }) {
        if (data && data.getExperience) {
          const { experience } = data.getExperience;
          if (experience && experience.length > 0) {
            let experienceList = [];
            for (let exp of experience) {
              let experienceOnj = {
                companyName: exp.Prev_Company_Name,
                location: exp.Prev_Company_Location,
                designation: exp.Designation,
                from: exp.Start_Date,
                to: exp.End_Date,
                experienceId: exp.Experience_Id,
                fileName: exp.File_Name ? exp.File_Name.split("?")[3] : "",
                fileNameWithoutFormat: exp.File_Name,
                fileValidationError: "",
              };
              experienceList.push(experienceOnj);
            }
            this.addExperience = experienceList;
            this.showSkip = true;
          } else {
            this.addExperience.push({
              companyName: "",
              location: "",
              designation: "",
              from: "",
              to: "",
              fileName: "",
              fileNameWithoutFormat: "",
              experienceId: 0,
              fileValidationError: "",
            });
          }
        }
        this.expLoading = false;
      },
      error() {
        this.expLoading = false;
      },
    },
  },

  computed: {
    formatDate() {
      return (date) => {
        return date ? moment(date).format("DD/MM/YYYY") : "";
      };
    },
  },

  mounted() {
    scrollScreenToTop();
  },

  methods: {
    updateFileName(fileName, index) {
      this.addExperience[index]["fileNameWithoutFormat"] = fileName;
    },

    onClickPrevBtn() {
      this.$emit("back-to-prev");
    },

    addMoreExperience() {
      this.validateExperience().then((isValid) => {
        if (isValid) {
          this.addExperience.push({
            companyName: "",
            location: "",
            designation: "",
            from: "",
            to: "",
            fileName: "",
            fileNameWithoutFormat: "",
            experienceId: 0,
            fileValidationError: "",
          });
        }
      });
    },

    emptyTO(index) {
      this.addExperience[index]["to"] = "";
      this.fromModal = false;
    },

    validateExperience() {
      return new Promise((resolve) => {
        let callCount = 0,
          isValidCount = 0;
        for (let eIndex in this.addExperience) {
          this.$refs["experienceForm" + eIndex][0]
            .validate()
            .then((validationResponse) => {
              let invalidFields = [];
              Object.keys(this.$refs).forEach((refName) => {
                const field = this.$refs[refName];
                if (
                  field &&
                  field.length > 0 &&
                  field[0].errors &&
                  field[0].errors.length > 0
                ) {
                  invalidFields.push(refName);
                }
              });
              if (invalidFields.length > 0) {
                let firstField = this.$refs[invalidFields[0]][0];
                firstField.$el.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                });
              }
              callCount += 1;
              let eIndexFileName =
                this.addExperience[eIndex]["fileNameWithoutFormat"];
              if (validationResponse && eIndexFileName) {
                isValidCount += 1;
                if (callCount === this.addExperience.length) {
                  resolve(isValidCount === this.addExperience.length);
                }
              } else if (!eIndexFileName) {
                this.addExperience[eIndex]["fileValidationError"] =
                  "Document is required";
                let form = this.$refs["experienceForm" + eIndex][0];
                form.$el.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                });
                resolve(false);
              }
            });
        }
      });
    },

    onSaveExperience() {
      this.apiCallCount = 0;
      for (let experience of this.addExperience) {
        this.validateExperience().then((isValid) => {
          if (isValid) {
            this.apiCallCount += 1;
            if (experience.experienceId) {
              this.updateExperienceDetails(experience);
            } else {
              this.addExperienceDetails(experience);
            }
          }
        });
      }
    },

    onSkipExperience() {
      this.$emit("experience-saved");
    },

    addExperienceDetails(experienceItem) {
      let vm = this;
      vm.saveLoading = true;
      vm.$apollo
        .mutate({
          mutation: ADD_EXPERIENCE,
          variables: {
            Org_Code: localStorage.getItem("orgCode"),
            Url_Hash: localStorage.getItem("urlHash"),
            Prev_Company_Name: experienceItem.companyName,
            Prev_Company_Location: experienceItem.location,
            Designation: experienceItem.designation,
            Start_Date: experienceItem.from,
            End_Date: experienceItem.to,
            File_Name: experienceItem.fileNameWithoutFormat,
          },
          client: "apolloClientA",
        })
        .then(() => {
          if (vm.apiCallCount === vm.addExperience.length) {
            vm.$emit("experience-saved");
          }
        })
        .catch((addErr) => {
          let snackbarData = {
            isOpen: true,
            message:
              addErr.graphQLErrors.length !== 0
                ? addErr.graphQLErrors[0].message
                : "Something went wrong. It could be a possibility due to network connectivity error..",
            type: "warning",
          };
          vm.showAlert(snackbarData);
          if (vm.apiCallCount === vm.addExperience.length) {
            vm.saveLoading = false;
          }
        });
    },

    updateExperienceDetails(experienceItem) {
      let vm = this;
      vm.saveLoading = true;
      vm.$apollo
        .mutate({
          mutation: UPDATE_EXPERIENCE,
          variables: {
            Org_Code: localStorage.getItem("orgCode"),
            Url_Hash: localStorage.getItem("urlHash"),
            Prev_Company_Name: experienceItem.companyName,
            Prev_Company_Location: experienceItem.location,
            Designation: experienceItem.designation,
            Start_Date: experienceItem.from,
            End_Date: experienceItem.to,
            File_Name: experienceItem.fileNameWithoutFormat,
            Experience_Id: experienceItem.experienceId,
          },
          client: "apolloClientA",
        })
        .then(() => {
          if (vm.apiCallCount === vm.addExperience.length) {
            vm.$emit("experience-saved");
          }
        })
        .catch((addErr) => {
          let snackbarData = {
            isOpen: true,
            message:
              addErr.graphQLErrors.length !== 0
                ? addErr.graphQLErrors[0].message
                : "Something went wrong. It could be a possibility due to network connectivity error..",
            type: "warning",
          };
          vm.showAlert(snackbarData);
          if (vm.apiCallCount === vm.addExperience.length) {
            vm.saveLoading = false;
          }
        });
    },

    removeExperience(index, experienceItem) {
      if (experienceItem.experienceId) {
        let vm = this;
        this.$apollo
          .mutate({
            mutation: DELETE_EXPERIENCE,
            variables: {
              Org_Code: localStorage.getItem("orgCode"),
              Url_Hash: localStorage.getItem("urlHash"),
              Experience_Id: experienceItem.experienceId,
            },
            client: "apolloClientA",
          })
          .then(() => {
            let snackbarData = {
              isOpen: true,
              message: "Deleted Successfully",
              type: "success",
            };
            vm.showAlert(snackbarData);
            vm.addExperience.splice(index, 1);
            if (index === 0 && vm.addExperience.length === 0) {
              vm.addExperience.push({
                companyName: "",
                location: "",
                designation: "",
                from: "",
                to: "",
                fileName: "",
                fileNameWithoutFormat: "",
                experienceId: 0,
              });
            }
          })
          .catch((deleteErr) => {
            let snackbarData = {
              isOpen: true,
              message:
                deleteErr.graphQLErrors.length !== 0
                  ? deleteErr.graphQLErrors[0].message
                  : "Something went wrong. It could be a possibility due to network connectivity error..",
              type: "warning",
            };
            vm.showAlert(snackbarData);
          });
      } else {
        this.addExperience.splice(index, 1);
      }
    },

    // show error or success message
    showAlert(snackbarData) {
      this.$store.commit("OPEN_SNACKBAR", snackbarData);
    },
  },
};
</script>
