/**
 * This function is ued to check the value exist in Array or Array of object.
 * based on objectKey we decide the list as array or array of object
 * We also can add Object in future when it needs
 **/
export function checkValExistsInList(list, searchValue, objectKey = null) {
  // if key is passed, then the list must be an array of object
  if (objectKey) {
    return list.some((el) =>
      el[`${objectKey}`]
        ? searchValue.toString().toLowerCase() ===
          el[`${objectKey}`].toString().toLowerCase()
        : false
    );
  } else {
    // list is array
    return list.some(
      (el) =>
        searchValue.toString().toLowerCase() === el.toString().toLowerCase()
    );
  }
}

export function base64ToFile(dataurl, filename) {
  const arr = dataurl.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n) {
    u8arr[n - 1] = bstr.charCodeAt(n - 1);
    n -= 1; // to make eslint happy
  }
  return new File([u8arr], filename, { type: mime });
}

export function scrollScreenToTop() {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}
